<template>
  <v-container id="mapContainer" fluid class="pa-2" tabindex="0">
    <DynamicDialog
      :show="showClearMapDialog"
      @dialogOk="clearMap()"
      @dialogCancel="showClearMapDialog = false"
      @update:showDyamicDialog="(state) => (showClearMapDialog = state)"
      max-width="642"
      okBtnColor="error"
      cancelBtnColor="none"
      cancelBtnVariant="outlined"
    >
      <template v-slot:title>Are you sure?</template>
      <template v-slot:content>
        <p class="pb-4">
          This action cannot be undone. All data added to the map will be
          removed.
        </p>
      </template>
      <template v-slot:okBtnTitle>Clear Map Data</template>
    </DynamicDialog>
    <h1 class="h1" style="display: none">Map</h1>
    <MapNavigate
      v-model:mapNavigateDialog="mapNavigateDialog"
      class="navigateElement"
    />
    <MapData
      v-model:mapDataDialog="mapDataDialog"
      @selectedIndicator="updateSelectedIndicator"
      v-model:selectedIndicator="selectedIndicator"
    />
    <MapServices
      :serviceTypeIds="serviceTypeIds"
      v-model:mapServicesDialog="mapServicesDialog"
      @closeServicesDialog="mapServicesDialog = false"
      v-model:loadAllServices="loadAllServices"
      v-model:customAreaCount="customAreaCount"
      v-model:clearDialogs="clearDialogs"
      @selectedTypes="loadingDelay"
    />
    <MapReports v-model:mapReportsDialog="mapReportsDialog" />
    <CustomAreaSelector
      v-model:dialog="mapCustomAreasDialog"
      v-model:markerCount="markerCount"
      v-model:clearDialogs="clearDialogs"
      @selectedCustomAreas="handleCustomAreaViewing"
    />
    <MapSettings
      v-model:mapSettingsDialog="mapSettingsDialog"
      v-model:hotspotFlag="hotspotFlag"
      v-model:boundaryFlag="boundaryFlag"
    />
    <v-row>
      <v-col cols="12" class="ma-0 pa-0">
        <!-- the map container -->
        <v-card
          tile
          style="overflow: initial; z-index: initial"
          class="ma-0 pa-0"
        >
          <!-- container for map Legend -->
          <div>
            <v-card class="mapLegend" elevation="0">
              <MapInfo
                v-model:mapInfoDialog="mapInfoDialog"
                :viewInfo="viewInfo"
                :areaMouseOverInfo="areaMouseOverInfo"
                :colourScheme="colourScheme"
                :lockedView="lockedView"
                :showDataOutsideBoundaries="showDataOutsideBoundaries"
                :showDataBoundariesSwitch="showDataBoundariesSwitch"
                :mapAggregationLevels="mapAggregationLevels"
                @lockViewChanged="handleLockViewChanged"
                @selectedDataLevelChanged="handleDataLevelSelected"
                @showDataOutsideBoundaries="handleDataBoundaryViewChanged"
              />
              <CustomAreaLegend
                ref="customAreaLegend"
                :customAreas="selectedCustomAreas"
                :zoomToArea="zoomToArea"
                :toggleAreaVisibility="toggleCustomAreaVisibility"
                :toggleOuterBoundareis="handleToggleOuterBoundareis"
                v-model:showOuterCustomAreasProp="showOuterCustomAreas"
                @highlightCABoundary="highlightCABoundary"
                @removeHighlightCABoundary="removeHighlightCABoundary"
              />
              <ServiceTypesLegend
                :selectedTypes="selectedTypes"
                :loadingServices="loadingServices"
                :serviceLoadPercentage="serviceLoadPercentage"
                @zoomToServiceType="zoomToServiceType"
                v-model:displayServicesOutsideCustomAreasProp="
                  displayServicesOutsideCustomAreas
                "
                v-model:areServicesClusteredProp="areServicesClustered"
                :selectedCustomAreas="selectedCustomAreas"
              />
            </v-card>
          </div>
          <!-- container for map buttons -->
          <div class="mapButtons">
            <v-tooltip
              v-model="showTooltip.mapNavigateButton"
              :open-on-hover="false"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  id="mapNavigateButton"
                  class="mapButton"
                  fab
                  icon="mdi-map-search-outline"
                  v-bind="props"
                  @click="mapNavigateDialog = true"
                  aria-label="navigate"
                  @mouseenter="(e) => e.currentTarget.focus()"
                  @mouseleave="(e) => e.currentTarget.blur()"
                  @focus="activateTooltip"
                  @blur="deactivateTooltip"
                  tabindex="0"
                ></v-btn>
              </template>
              <template #default>
                <span>Navigate</span>
              </template>
            </v-tooltip>
            <v-tooltip
              right
              v-model="showTooltip.mapDataButton"
              :open-on-hover="false"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  id="mapDataButton"
                  class="mapButton"
                  fab
                  icon="mdi-layers-plus"
                  v-bind="props"
                  @click="mapDataDialog = true"
                  aria-label="data"
                  @mouseenter="(e) => e.currentTarget.focus()"
                  @mouseleave="(e) => e.currentTarget.blur()"
                  @focus="activateTooltip"
                  @blur="deactivateTooltip"
                  tabindex="0"
                >
                </v-btn>
              </template>
              <span>Data</span>
            </v-tooltip>
            <v-tooltip
              right
              v-model="showTooltip.mapServicesButton"
              :open-on-hover="false"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  id="mapServicesButton"
                  class="mapButton"
                  fab
                  icon="mdi-map-marker"
                  v-bind="props"
                  @click="
                    sendAnalytics('view_services_on_map');
                    mapServicesDialog = true;
                  "
                  aria-label="services"
                  @mouseenter="(e) => e.currentTarget.focus()"
                  @mouseleave="(e) => e.currentTarget.blur()"
                  @focus="activateTooltip"
                  @blur="deactivateTooltip"
                  tabindex="0"
                >
                </v-btn>
              </template>
              <span>Services</span>
            </v-tooltip>
            <v-tooltip
              right
              v-model="showTooltip.mapReportsButton"
              :open-on-hover="false"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  id="mapReportsButton"
                  class="mapButton"
                  fab
                  icon="mdi-file-chart-outline"
                  v-bind="props"
                  @click="mapReportsDialog = true"
                  aria-label="reports"
                  @mouseenter="(e) => e.currentTarget.focus()"
                  @mouseleave="(e) => e.currentTarget.blur()"
                  @focus="activateTooltip"
                  @blur="deactivateTooltip"
                  tabindex="0"
                >
                </v-btn>
              </template>
              <span>Reports</span>
            </v-tooltip>
            <v-tooltip
              right
              v-model="showTooltip.mapCustomAreasButton"
              :open-on-hover="false"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  id="mapCustomAreasButton"
                  class="mapButton"
                  fab
                  icon="mdi-vector-polygon"
                  v-bind="props"
                  @click="
                    sendAnalytics('view_custom_areas_on_map');
                    mapCustomAreasDialog = true;
                  "
                  aria-label="custom areas"
                  @mouseenter="(e) => e.currentTarget.focus()"
                  @mouseleave="(e) => e.currentTarget.blur()"
                  @focus="activateTooltip"
                  @blur="deactivateTooltip"
                  tabindex="0"
                >
                </v-btn>
              </template>
              <span>Custom Areas</span>
            </v-tooltip>
            <v-tooltip
              right
              v-model="showTooltip.mapSettingsButton"
              :open-on-hover="false"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  id="mapSettingsButton"
                  class="mapButton"
                  fab
                  icon="mdi-cog"
                  v-bind="props"
                  @click="mapSettingsDialog = true"
                  aria-label="settings"
                  @mouseenter="(e) => e.currentTarget.focus()"
                  @mouseleave="(e) => e.currentTarget.blur()"
                  @focus="activateTooltip"
                  @blur="deactivateTooltip"
                  tabindex="0"
                >
                </v-btn>
              </template>
              <span>Settings</span>
            </v-tooltip>
            <v-tooltip
              right
              v-model="showTooltip.mapShareMapButton"
              :open-on-hover="false"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  id="mapShareMapButton"
                  class="mapButton"
                  fab
                  icon="mdi-share-variant"
                  v-bind="props"
                  @click="
                    shareMap();
                    shareDialog = true;
                  "
                  aria-label="share map"
                  @mouseenter="(e) => e.currentTarget.focus()"
                  @mouseleave="(e) => e.currentTarget.blur()"
                  @focus="activateTooltip"
                  @blur="deactivateTooltip"
                  tabindex="0"
                >
                </v-btn>
              </template>
              <span>Share Map</span>
            </v-tooltip>
            <v-tooltip
              v-if="showHideClearMapButton"
              right
              v-model="showTooltip.mapClearMapButton"
              :open-on-hover="false"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  id="mapClearMapButton"
                  class="mapButton error"
                  fab
                  icon="mdi-layers-off"
                  v-bind="props"
                  @click="showClearMapDialog = true"
                  aria-label="clear map"
                  @mouseenter="(e) => e.currentTarget.focus()"
                  @mouseleave="(e) => e.currentTarget.blur()"
                  @focus="activateTooltip"
                  @blur="deactivateTooltip"
                  tabindex="0"
                  style="background-color: #b00020 !important"
                >
                </v-btn>
              </template>
              <span>Clear Map</span>
            </v-tooltip>
            <v-dialog v-model="shareDialog" width="600" id="shareMapDialog">
              <v-card-title
                :style="
                  'background-color: ' +
                  this.$store.state.config.siteConfig.toolbar_colour
                "
                class="text-h6 pa-4"
              >
                Share Map
              </v-card-title>
              <v-card tile>
                <v-card-text
                  v-if="!$store.state.config.siteConfig.is_public_site"
                  class="ps-5 pb-0"
                  >Important: Shared links of the map can only be accessed by
                  individuals who have both a Local Insight account and are
                  members of your organisation
                </v-card-text>
                <v-card-actions class="justify-start ps-5 mt-3">
                  <v-progress-circular
                    v-if="loadingShareableLink"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <span class="ml-2" v-if="loadingShareableLink"
                    >Creating shareable link...</span
                  >
                  <v-text-field
                    v-else
                    :value="shareUrl"
                    type="text"
                    readonly
                    variant="outlined"
                    density="compact"
                    rounded="0"
                    @click="this.$refs.shareUrlField.select()"
                    append-icon="mdi-content-copy"
                    label:append-icon="Click to Copy Link"
                    @keydown.enter="copyToClipboard"
                    @click:append="copyToClipboard"
                    :hint="clipboardMessage"
                    persistent-hint
                    ref="shareUrlField"
                    autocomplete="off"
                  ></v-text-field>
                </v-card-actions>
                <v-divider></v-divider>
                <v-card class="pa-1 pb-2">
                  <v-btn
                    color="primary"
                    tile
                    class="pa-2 mt-1 ml-2"
                    title="back to list"
                    @click="shareDialog = false"
                    aria-label="back"
                  >
                    back
                  </v-btn>
                </v-card>
              </v-card>
            </v-dialog>
          </div>
          <div style="width: 100%">
            <div id="pano"></div>

            <div
              ref="mapCanvas"
              id="mapCanvas"
              class="map-canvas"
              style="width: 100%"
              :style="'height: ' + (pageHeight - 44) + 'px;'"
            ></div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* global google */

//components for the map options
import MapData from "@/components/MapData";
import MapInfo from "@/components/MapInfo";
import CustomAreaLegend from "@/components/CustomAreaLegend";
import ServiceTypesLegend from "@/components/ServiceTypesLegend";
import MapServices from "@/components/MapServices";
import MapReports from "@/components/MapReports";
import CustomAreaSelector from "@/components/CustomAreaSelector";
import MapSettings from "@/components/MapSettings";
import MapNavigate from "@/components/MapNavigate";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { loadGoogleMaps } from "@/mixins/LoadGoogleMaps";
import { toRaw } from "vue";
import { getURLParam } from "@/mixins/GetURLParam";
import { copyToClipboard } from "@/mixins/CopyToClipboard";
import DynamicDialog from "@/components/DynamicDialog.vue";
import { useDisplay } from "vuetify";

// store map here so vue doesn't wrap it in a proxy - Google doesn't like that.
let map = null;
let displayAreaQueue = Promise.resolve();

export default {
  name: "GoogleMap",
  data: () => ({
    pageHeight: useDisplay().height,
    showClearMapDialog: false,
    showTooltip: {},
    clipboardMessage: "",
    savedMapId: null,
    serviceTypeIds: [],
    loadingShareableLink: false,
    shareDialog: false,
    shareUrl: "",
    mapsApiLoaded: false,
    builtIcons: {},
    lockedView: false,
    icons: [],
    clearing: false,
    loadingServices: false,
    polygonsWithDataset: null,
    selectedIndicator: null,
    selectedTypes: null,
    loadedTypes: null,
    clearDialogs: false,
    selectedCustomAreas: [],
    mapCustomAreasDialog: false,
    customAreaServiceIds: [],
    displayServicesOutsideCustomAreas: true,
    showOuterCustomAreas: false,
    mouseFeature: null,
    mapDataDialog: false,
    mapInfoDialog: false,
    mapServicesDialog: false,
    mapReportsDialog: false,
    mapSettingsDialog: false,
    mapNavigateDialog: false,
    hotspotFlag: "all",
    zoom: 4,
    previousZoom: 4,
    latestQuintileLevel: 99,
    latestIndicatorId: 0,
    center: { lat: null, lng: null },
    hasDefaultView: false,
    settingDefaultView: false,
    resettingDefaultView: false,
    firstLoad: false,
    maxZoomLevelForClustering: 17,
    defaultZoomAreaLevels: {
      1: 6,
      2: 6,
      3: 6,
      4: 6,
      5: 6,
      6: 6,
      7: 6,
      8: 6, // LA
      9: 5,
      10: 5, //Ward
      11: 2, // MSOA
      12: 2,
      13: 1, // LSOA
      14: 1,
      15: 1,
      16: 1,
      17: 1,
      18: 1,
      19: 1,
      20: 1,
      21: 1,
      22: 1,
    },
    zoomAreaLevels: {},
    dataLevels: {},
    loadAllServices: false,
    markers: [],
    infoWindows: {},
    currentMarkers: [],
    markerCluster: null,
    polygonObjects: [],
    data_layer: null,
    idleTimeout: null,
    areaMouseOverInfo: {
      areaInfo: null,
      freeze: false,
    },
    viewInfo: {
      viewportInfo: {},
      quintiles: { q1_min: "loading" },
      indicatorInfo: {
        id: null,
      },
      numeratorInfo: {},
    },
    mapOptions: {
      gestureHandling: "greedy",
    },
    colourScheme: [],
    clientBoundary: [],
    clientBoundaryGeoJson: null,
    boundaryFlag: false,
    mapBoundariesGeoJson: {},
    showDataOutsideBoundaries: true,
    serviceLoadPercentage: 0,
    selectedDataLevel: null,
    previousDataLevel: null,
    areServicesClustered: true,
    // Default available map aggregation levels
    defaultMapAggregationLevels: [1, 2, 5, 6],
    mapAggregationLevels: [],
    previousGeometriesInViewportCount: 0,
    previousGeometriesPartiallyInViewport: false,
  }),
  components: {
    MapData,
    MapInfo,
    CustomAreaLegend,
    ServiceTypesLegend,
    MapServices,
    MapReports,
    CustomAreaSelector,
    MapSettings,
    MapNavigate,
    DynamicDialog,
  },
  props: {
    model: null,
    item: {},
    reportMapPrimaryAreaCode: null,
    relatedModelResults: {},
  },
  beforeUnmount() {
    this.emit.emit("systemBusy", false);
  },
  computed: {
    showHideClearMapButton() {
      return (
        this.selectedCustomAreas.length ||
        this.markers.length ||
        this.selectedIndicator ||
        Object.keys(this.mapBoundariesGeoJson).length
      );
    },
    userHasAccess() {
      // Check if it's on staging
      return (
        window.location.hostname.includes("stg") ||
        window.location.hostname.includes("dev")
      );
    },
    navigateResult: {
      get() {
        return this.$store.state.navigateResult;
      },
      set(value) {
        this.$store.commit("setNavigateResult", value);
      },
    },
    scaleOfScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 0.5;
        case "sm":
          return 0.6;
        case "md":
          return 0.8;
        case "lg":
          return 0.8;
        case "xl":
          return 0.9;
        default:
          return 0.5;
      }
    },
    marginBottom() {
      return this.$vuetify.breakpoint.name === "md" ? "0" : "auto";
    },
    markerCount() {
      return this.markers.length;
    },
    customAreaCount() {
      return this.selectedCustomAreas.length;
    },
    showDataBoundariesSwitch() {
      return Object.keys(this.mapBoundariesGeoJson).length > 0;
    },
  },
  mounted: async function () {
    this.boundaryFlag = this.$store.getters.customClientConfig.show_boundary;
    this.zoomAreaLevels = { ...this.defaultZoomAreaLevels };
    this.mapAggregationLevels = [...this.defaultMapAggregationLevels];
    this.zoom = this.$store.state.config.siteConfig.initialMapZoom.zoom;
    this.center.lat = this.$store.state.config.siteConfig.initialMapZoom.lat;
    this.center.lng = this.$store.state.config.siteConfig.initialMapZoom.lng;
    // If there is a saved map, attempt to fill the settings
    this.savedMapId = getURLParam("savedmap");
    if (this.savedMapId) {
      this.emit.emit("systemBusy", true);
      try {
        const response = await this.$axios.get("/saved-map/" + this.savedMapId);
        const responseData = response.data.data;
        this.selectedIndicator = responseData.indicator_id;
        this.center = responseData.center;
        this.zoom = responseData.zoom;
        this.lockedView = responseData.locked_view;
        this.selectedCustomAreas = responseData.custom_area_ids;
        this.serviceTypeIds = responseData.service_type_ids;
        this.hotspotFlag = responseData.hotspot_flag;
        this.viewInfo.viewportInfo.dataLevel = responseData.data_level;
        this.boundaryFlag = responseData.client_boundary;

        if (response.data.message) {
          this.emit.emit("systemMessage", {
            title: "Error",
            message: response.data.message,
            timeout: -1,
            colour: "error",
          });
        }

        // Call the functions to load the relevant data
        await this.loadMap();
        // Make sure map is fully loaded before attempting to get bounds from maps
        google.maps.event.addListenerOnce(map, "idle", () => {
          this.emit.emit("systemBusy", true);
          this.viewInfo.viewportInfo.xmin = map
            .getBounds()
            .getSouthWest()
            .lng();
          this.viewInfo.viewportInfo.ymin = map
            .getBounds()
            .getSouthWest()
            .lat();
          this.viewInfo.viewportInfo.xmax = map
            .getBounds()
            .getNorthEast()
            .lng();
          this.viewInfo.viewportInfo.ymax = map
            .getBounds()
            .getNorthEast()
            .lat();
          this.updateSelectedIndicator(this.selectedIndicator);
          this.handleCustomAreaViewing(this.selectedCustomAreas);
          this.emit.emit("systemBusy", false);
        });
      } catch (error) {
        // handle error
        this.emit.emit("systemMessage", {
          title: "Error! Failed to load map",
          message: error.response?.data?.message || error.message,
          timeout: -1,
          colour: "error",
        });
        await this.loadMap();
      }
    } else {
      await this.loadMap();
    }

    // set up listener for clicking on streetview
    const wrapper = document.getElementById("mapContainer");

    wrapper.addEventListener("click", (event) => {
      // make sure it's a button that's been pressed
      const isStreetViewButton = event.target.id === "streetViewButton";
      if (!isStreetViewButton) {
        return;
      }

      this.openStreetView(
        event.target.getAttribute("lat"),
        event.target.getAttribute("lng"),
      );
    });
  },

  methods: {
    async loadMap() {
      try {
        const loadedMap = await loadGoogleMaps(this.center, this.zoom);
        if (loadedMap) {
          map = loadedMap;
          setTimeout(() => {
            this.addAltTextToIframe();
          }, 2000);
          this.mapSetup();
          return map;
        }
      } catch (error) {
        console.error("Error loading map:", error);
        throw error;
      }
    },
    addAltTextToIframe() {
      var text = "Interactive Map showing selected location based data";
      // Find all iframe elements on the page
      var iframes = document.querySelectorAll("iframe");
      // Loop through each iframe and add the alternative text
      iframes.forEach(function (iframe) {
        iframe.setAttribute("title", text);
        iframe.setAttribute("alt", text);
      });
    },
    shareMap() {
      this.loadingShareableLink = true;

      const params = {
        indicator_id: this.selectedIndicator,
        center: map.getCenter(),
        zoom: map.getZoom(),
        locked_view: this.lockedView,
        custom_area_ids: this.selectedCustomAreas,
        service_types: this.selectedTypes ?? [],
        hotspot_flag: this.hotspotFlag,
        data_level: this.viewInfo.viewportInfo.dataLevel,
        client_boundary: this.clientBoundary.length > 0,
      };

      this.$axios
        .post("/saved-map/store", params)
        .then((response) => {
          this.shareUrl = `${window.location.origin}/#/map?savedmap=${response.data.id}`;
          this.loadingShareableLink = false;
        })
        .catch((error) => {
          console.error("Error saving map: ", error);
        });
    },
    async copyToClipboard() {
      if (copyToClipboard(this.shareUrl)) {
        this.clipboardMessage = "The link has been copied to your clipboard";
        setTimeout(() => {
          this.clipboardMessage = "";
        }, 3000);
      } else {
        this.clipboardMessage =
          "The value has NOT been copied to your clipboard";
        setTimeout(() => {
          this.clipboardMessage = "";
        }, 3000);
      }
    },
    zoomToServiceType(type) {
      // Check if there are markers to zoom to
      if (this.markers.length > 0) {
        // get the bounds of the points
        var bounds = new google.maps.LatLngBounds();

        // get the extent of visible markers
        this.markers.forEach(function (marker) {
          if (marker.type_id === type.id) {
            bounds.extend(marker.position);
          }
        });

        // fit the map to show them
        map.fitBounds(bounds);
      }
    },
    /**
     * Toggles the visibility of a custom area on the Google Map.
     * This operation is performed in a queued sequence to ensure
     * that multiple visibility changes do not conflict with each other.
     */
    async toggleCustomAreaVisibility(areasToShow) {
      displayAreaQueue = displayAreaQueue
        .then(async () => {
          if (!areasToShow.length) {
            this.removePolygons();
            delete this.mapBoundariesGeoJson.customareas;
            // reload services
            this.customAreaServiceIds = [];
            this.loadSelectedTypes(false);
            return;
          }

          // Get new geometries
          await this.getCustomAreasPolygon(areasToShow);
        })
        .catch((err) => {
          console.error("Task failed:", err);
        });

      return displayAreaQueue; // Return the promise if you need to await the queue
    },
    /**
     * for given array of objects find index of object with key matching value
     * e.g. will return 1 if searching for 5678 [{1234:'blah'},{5678:'blah'}]
     */
    findArrayObjectKeyIndex(targetArray, objectVal) {
      const idx = targetArray
        .map((g) => {
          return Object.keys(g)[0];
        })
        .findIndex((k) => k == objectVal);

      return idx;
    },
    async zoomToArea(areaID) {
      const polygon = await this.$axios.post("/custom-areas-polygon", [areaID]);
      const { type, coordinates } = polygon.data.features[0].geometry;

      const bounds = new google.maps.LatLngBounds();

      const addCoordinatesToBounds = (coords) => {
        coords.forEach((coord) => {
          bounds.extend({ lat: coord[1], lng: coord[0] });
        });
      };

      if (type === "Polygon") {
        addCoordinatesToBounds(coordinates[0]);
      } else if (type === "MultiPolygon") {
        coordinates.forEach((polygonCoords) =>
          addCoordinatesToBounds(polygonCoords[0]),
        );
      }

      map.fitBounds(bounds);
    },
    handleLockViewChanged(newValue) {
      this.lockedView = newValue;
      if (!this.lockedView) {
        this.updateViewDetails();
      }
    },
    handleDataLevelSelected(newValue) {
      this.selectedDataLevel = newValue;
      if (!this.lockedView) {
        this.updateViewDetails();
      }
    },
    handleDataBoundaryViewChanged(newValue) {
      this.showDataOutsideBoundaries = newValue;
      this.updateViewDetails({ forceUpdate: true });
    },
    openStreetView(lat, lng) {
      const streetViewLocation = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      };

      // create a new Streetview instance
      const panorama = new google.maps.StreetViewPanorama(map.getDiv(), {
        position: streetViewLocation,
        pov: {
          heading: 34,
          pitch: 10,
        },
      });

      // allow close option
      panorama.setOptions({
        enableCloseButton: true,
      });

      // set the map to the streetview - controls in top left
      map.setStreetView(panorama);
      map.controls[google.maps.ControlPosition.TOP_LEFT].push();
    },
    loadServiceTypeIcons() {
      return new Promise((resolve, reject) => {
        this.$axios
          .get("/service-type-icons")
          .then((response) => {
            this.icons = response.data;
            resolve(response.data); // Resolve the promise with the response data
          })
          .catch((error) => {
            console.error(error);
            reject(error); // Reject the promise with the error
          });
      });
    },
    async clearMap() {
      this.showClearMapDialog = false;
      this.clearing = true;
      this.hotspotFlag = "all";
      this.selectedIndicator = null;
      this.viewInfo.indicatorInfo.id = null;
      this.viewInfo.viewportInfo.ind_id = null;
      this.selectedCustomAreas = [];
      this.selectedTypes = [];
      this.areaMouseOverInfo.areaInfo = null;
      this.areaMouseOverInfo.freeze = false;
      this.customAreaServiceIds = [];
      await this.clearMarkers();
      this.removePolygons();
      this.clearDialogs = true;
      this.markerCluster = null;
      this.clientBoundary.forEach((polygon) => {
        toRaw(polygon).setMap(null);
      });
      this.boundaryFlag = false;
      this.mapBoundariesGeoJson = {};

      // remove data
      let dataFeatures = map.data;
      dataFeatures.forEach(function (dataFeature) {
        dataFeatures.remove(dataFeature);
      });
      this.clearing = false;
      // reset the flag passed in
      setTimeout(() => {
        this.clearDialogs = false;
      }, 500);
    },
    getPolygonsWithDataset() {
      this.emit.emit("systemBusy", true);
      // add the the hotspot flag go determine what data to get back
      this.viewInfo.viewportInfo.hotspotFlag = this.hotspotFlag;

      //add displayed boundaries/custom areas visualisations flag into backend data (all this for a flat array of geojson)
      this.viewInfo.viewportInfo.mapBoundariesGeoJson = [];
      if (
        Object.prototype.hasOwnProperty.call(
          this.mapBoundariesGeoJson,
          "boundaries",
        )
      ) {
        this.viewInfo.viewportInfo.mapBoundariesGeoJson.push(
          this.mapBoundariesGeoJson.boundaries,
        );
      }
      if (
        Object.prototype.hasOwnProperty.call(
          this.mapBoundariesGeoJson,
          "customareas",
        )
      ) {
        this.viewInfo.viewportInfo.mapBoundariesGeoJson = [
          ...this.viewInfo.viewportInfo.mapBoundariesGeoJson,
          ...this.mapBoundariesGeoJson.customareas.map((g) => g),
        ];
      }

      this.viewInfo.viewportInfo.showDataOutsideBoundaries =
        this.showDataOutsideBoundaries;

      // set the colours
      var colors = this.colourScheme;
      var color;

      this.$axios
        .post("/data-geometries", this.viewInfo.viewportInfo)
        .then(
          function (response) {
            // handle success
            this.polygonsWithDataset = response.data;

            let features = map.data;
            features.forEach(function (feature) {
              features.remove(feature);
            });
            if (this.polygonsWithDataset.features) {
              map.data.addGeoJson(this.polygonsWithDataset);
              this.addDataLayerListeners();
              map.data.setStyle(function (feature) {
                var quintile = feature.getProperty("quintile");
                switch (quintile) {
                  case 1:
                    color = "#" + colors[0];
                    break;
                  case 2:
                    color = "#" + colors[1];
                    break;
                  case 3:
                    color = "#" + colors[2];
                    break;
                  case 4:
                    color = "#" + colors[3];
                    break;
                  case 5:
                    color = "#" + colors[4];
                    break;
                  default:
                    color = "#" + colors[4];
                }
                return {
                  fillColor: color,
                  fillOpacity: 0.5,
                  strokeColor: "#FFFFFF",
                  strokeWeight: 0.5,
                };
              });
            }
            this.emit.emit("systemBusy", false);
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemBusy", false);
            // handle error
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Failed to load data",
              timeout: -1,
              colour: "error",
            });
            console.error(error);
          }.bind(this),
        );
    },
    buildIndicatorQuintiles(forceUpdate = true) {
      if (
        this.viewInfo.viewportInfo.dataLevel != this.latestQuintileLevel ||
        this.viewInfo.indicatorInfo.id != this.latestIndicatorId ||
        forceUpdate
      ) {
        this.viewInfo.quintiles = {
          q1_min: "loading",
        };

        this.$axios
          .get(
            "/standard-data-quintile-range/" +
              this.viewInfo.viewportInfo.ind_id +
              "/" +
              this.viewInfo.viewportInfo.dataLevel +
              "/" +
              this.viewInfo.viewportInfo.hotspotFlag,
          )
          .then((response) => {
            // handle success
            this.viewInfo.quintiles = response.data;
          })
          .catch(
            function (error) {
              // handle error
              console.error(error);
            }.bind(this),
          );

        // update our latest fetched quintile info
        this.latestQuintileLevel = this.viewInfo.viewportInfo.dataLevel;
        this.latestIndicatorId = this.viewInfo.indicatorInfo.id;
      }
    },
    getDataCount() {
      this.$axios
        .post("/get-data-count", this.viewInfo.viewportInfo)
        .then(
          function (response) {
            // handle success
            if (response.data > 0) {
              // call this for the information panel
              this.buildIndicatorQuintiles();
            } else {
              this.viewInfo.quintiles.q1_min = null;
            }
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Failed to load data",
              timeout: -1,
              colour: "error",
            });
            console.error(error);
          }.bind(this),
        );
    },
    async loadingDelay(types) {
      this.loadingServices = true;
      setTimeout(async () => {
        this.selectedTypes = types.filter((type) => type.isSelected);
        await this.loadServiceTypeIcons(); // Ensure this method is awaited
        this.loadSelectedTypes();
      }, 500);
    },
    async loadSelectedTypes(zoomIn = true) {
      if (this.selectedTypes === null) {
        return;
      }
      this.emit.emit("systemBusy", {
        busy: true,
        showProgressLoader: false,
      });
      this.loadingServices = true;

      // clear everything currently loaded
      await this.clearMarkers();

      let pointsToLoad = [];

      // run through every selected type and add their services to the list
      this.selectedTypes.forEach((type) => {
        pointsToLoad = [].concat(pointsToLoad, type.services);
      });

      // do we want to show services only withing currently displayed custom areas?
      if (
        !this.displayServicesOutsideCustomAreas &&
        this.selectedCustomAreas.length &&
        this.polygonObjects.length > 0
      ) {
        pointsToLoad = pointsToLoad.filter((point) =>
          this.customAreaServiceIds.includes(point.id),
        );
      }

      // keep a track of postcodes we've already processed in case we get duplicates we need to offset
      let processedPostcodes = [];

      // get the libraries we need
      const { AdvancedMarkerElement } =
        await google.maps.importLibrary("marker");
      const { InfoWindow } = await google.maps.importLibrary("maps");

      // run through each point
      for (let i = 0; i < pointsToLoad.length; i++) {
        // grab the top of the list
        let point = pointsToLoad[i];

        // check if we have an icon built for this type, if not, make one
        if (typeof this.builtIcons[point.type_id] == "undefined") {
          let svgContent =
            '<svg xmlns="http://www.w3.org/2000/svg" height="25px" width="25px"><path d="' +
            this.icons[point.icon].svg_path +
            '" fill="' +
            point.colour +
            '"/></svg>';

          // convert the svg to a png
          const blob = await this.svgToPng(svgContent, 25, 25);
          const iconUrl = URL.createObjectURL(blob);

          this.builtIcons[point.type_id] = iconUrl;
        }

        // check if we've already processed this postcode somewhere
        if (processedPostcodes.includes(point.postcode)) {
          // if we have, offset the coordinates
          point.lat = parseFloat(point.lat) + this.getRandomOffset();
          point.lng = parseFloat(point.lng) + this.getRandomOffset();
        } else {
          // if we've not processed this one before, keep track of it for next time
          processedPostcodes.push(point.postcode);
        }

        let markerIcon = this.builtIcons[point.type_id];

        // Create an HTMLImageElement for the marker content
        let imgElement = document.createElement("img");
        imgElement.src = markerIcon;
        imgElement.width = 25;
        imgElement.height = 25;
        imgElement.id = point.id;
        imgElement.setAttribute("map-marker", "true");

        let marker = new AdvancedMarkerElement({
          position: { lat: parseFloat(point.lat), lng: parseFloat(point.lng) },
          content: imgElement,
        });

        // add  our custom propertes
        marker.id = point.id;
        marker.type_id = point.type_id;

        marker.addListener(
          "click",
          () => {
            if (!this.infoWindows[point.id]) {
              this.infoWindows[point.id] = new InfoWindow({
                content: "",
                maxWidth: 200,
              });
            }

            const infoWindow = this.infoWindows[point.id];
            infoWindow.setContent(this.getInfoContent(point));
            infoWindow.setPosition(marker.position);
            infoWindow.open(map, marker);
          },
          { passive: true },
        );

        // add it to the array to refer back to later
        this.markers.push(marker);

        //nullify the point to free up memory
        pointsToLoad[i] = null;

        // update the loading percentage
        this.serviceLoadPercentage = Math.round(
          (i / pointsToLoad.length) * 100,
        );

        // every 10% of the way through, take a break
        if (i % Math.round(pointsToLoad.length / 10) === 0) {
          await new Promise((resolve) => setTimeout(resolve, 0));
        }
      }

      // clear out the postcodes we've processed
      processedPostcodes = [];

      // add them to the clusterer if clustering is enabled
      if (this.areServicesClustered) {
        this.markerCluster = new MarkerClusterer({
          markers: this.markers,
          map,
        });

        this.markerCluster.addListener("clusteringbegin", () => {
          this.loadingServices = true;
        });

        this.markerCluster.addListener("clusteringend", () => {
          this.loadingServices = false;
        });
      } else {
        // If clustering is disabled, add markers directly to the map
        this.markers.forEach((marker) => marker.setMap(map));
      }

      if (
        zoomIn &&
        this.markers.length > 0 &&
        !this.firstLoad &&
        !this.savedMapId
      ) {
        this.zoomToPoints();
      }
      this.firstLoad = false;
      this.loadingServices = false;
      this.emit.emit("systemBusy", false);
    },
    async clearMarkers() {
      return new Promise((resolve) => {
        this.markers.forEach((marker) => marker.setMap(null));
        this.markers = [];

        // Clear the clusterer
        if (this.markerCluster) {
          this.markerCluster.clearMarkers();
        }

        resolve();
      });
    },
    svgToPng(svgContent, width, height) {
      return new Promise((resolve, reject) => {
        const svgBlob = new Blob([svgContent], {
          type: "image/svg+xml;charset=utf-8",
        });
        const url = URL.createObjectURL(svgBlob);

        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            URL.revokeObjectURL(url);
            resolve(blob);
          }, "image/png");
        };
        img.onerror = (err) => {
          URL.revokeObjectURL(url);
          reject(err);
        };
        img.src = url;
      });
    },
    addDataLayerListeners() {
      // add listener to see if anything changes
      // mouse over to show the area & value
      map.data.addListener(
        "mouseover",
        function (event) {
          if (!this.areaMouseOverInfo.freeze) {
            //update the data with the event, which includes the mouse overed feature
            this.areaMouseOverInfo.areaInfo = event;

            // take a note of the feature clicked
            this.mouseFeature = event.feature;

            // up the border as a highlight
            map.data.overrideStyle(this.mouseFeature, {
              strokeWeight: 3.5,
            });
          }
        }.bind(this),
      );

      map.data.addListener(
        "mouseout",
        function () {
          //update the data with the event, which includes the mouse overed feature
          if (!this.areaMouseOverInfo.freeze) {
            this.areaMouseOverInfo.areaInfo = null;

            // reset border
            map.data.overrideStyle(this.mouseFeature, {
              strokeWeight: 0.5,
            });
          }
        }.bind(this),
      );

      // click to freeze the selection
      map.data.addListener(
        "click",
        function (event) {
          // update the data with the event, which includes the mouse overed feature
          // work around for now to avoid propagation
          if (event.alreadyCalled_) {
            //catch second click propagated up to 'map' from the data_layer
          } else {
            event.alreadyCalled_ = true;
            this.areaMouseOverInfo.freeze = !this.areaMouseOverInfo.freeze;

            // if we've just set it false, remove the area highlight
            if (!this.areaMouseOverInfo.freeze) {
              map.data.overrideStyle(this.mouseFeature, {
                strokeWeight: 0.5,
              });

              // also update to the currently mouse overed feature
              this.areaMouseOverInfo.areaInfo = event;
              this.mouseFeature = event.feature;
              map.data.overrideStyle(this.mouseFeature, {
                strokeWeight: 3.5,
              });
            }
          }
        }.bind(this),
      );
    },
    getInfoContent(feature) {
      const postcode = feature.postcode;
      const labels = feature.labels;

      // Add a style attribute for left text alignment
      let content = `<div style="text-align: left;">`;
      if (this.$store.state.config.siteConfig.site_country !== "aus") {
        // Add postcode with left alignment
        content += `<div><h4>Postcode:</h4> ${postcode}</div>`;
      }

      // Run through any labels and add them all in, aligned to the left
      for (const key in labels) {
        if (Object.prototype.hasOwnProperty.call(labels, key)) {
          let labelValue = labels[key].trim();

          if (labelValue) {
            // Check if the labelValue is a URL and convert it to a link
            if (this.isURL(labelValue)) {
              labelValue = `<a href="${labelValue}" target="_blank">${labelValue}</a>`;
            }

            content += `<div><h4>${key}:</h4> ${labelValue}</div>`;
          }
        }
      }

      // Add the streetview button at the end
      content += `<div>
    <button
      id="streetViewButton"
      lat="${feature.lat}"
      lng="${feature.lng}"
      class="v-btn v-btn--color-primary theme--dark v-size--small mt-4"
      style="background-color: #1976D2; color: #ffffff; border-radius: 0; padding: 7px;"
    >
      View Streetview
    </button>
  </div>`;

      // Close the main container div
      content += `</div>`;

      return content;
    },

    isURL(str) {
      const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
      return urlRegex.test(str);
    },
    updateViewDetails({ forceUpdate = false } = {}) {
      //PostGis docs - ST_MakeEnvelope(float xmin, float ymin, float xmax, float ymax, integer srid=unknown)
      // update view info
      if (map) {
        this.viewInfo.viewportInfo.xmin = map.getBounds().getSouthWest().lng();
        this.viewInfo.viewportInfo.ymin = map.getBounds().getSouthWest().lat();
        this.viewInfo.viewportInfo.xmax = map.getBounds().getNorthEast().lng();
        this.viewInfo.viewportInfo.ymax = map.getBounds().getNorthEast().lat();
      }

      if (this.selectedDataLevel) {
        //data level selected from dropdown
        this.setSelectedDataLevel();
      } else {
        //map zoom data level
        this.setZoomDataLevel();
      }

      //nothing further if data is same
      if (!this.viewDataChanged() && !forceUpdate) {
        return;
      }

      if (this.viewInfo.indicatorInfo.id) {
        this.getDataCount();
        this.getPolygonsWithDataset();
      }
    },
    /** whether need to fetch figures and visuals (save some backend calls) */
    viewDataChanged() {
      /**
       * Do not need to fetch indicator visualisations and quintile figures if:
       * i) view is locked (data level fixed) and zooming closer
       * ii) view is unlocked, zooming closer and new data level is same as previous zoom
       * iii) showing data inside boundaries, zooming out, view is locked or data level same as previous,
       *      no boundaries were previously partially in view(backend only downloads data in viewport)
       * iv) showing data inside boundaries no new geographies (boundary/custom area) have come into
       *      the viewport and no geometries were previously partially in the viewport e.g. dragging the map
       */
      let response = true;

      let countGeometriesInViewport = this.countGeometriesInViewport();
      let geometriesPartiallyInViewport =
        this.checkGeometriesPartiallyInViewport();

      const lockedDataZoomIn =
        this.lockedView && map.getZoom() > this.previousZoom;

      const unlockedDataZoomInSameDataLevel =
        !this.lockedView &&
        map.getZoom() > this.previousZoom &&
        this.viewInfo.viewportInfo.dataLevel === this.previousDataLevel;

      const dataInsideBoundariesZoomingOut =
        !this.showDataOutsideBoundaries &&
        map.getZoom() < this.previousZoom &&
        (this.lockedView ||
          this.viewInfo.viewportInfo.dataLevel === this.previousDataLevel) &&
        countGeometriesInViewport === this.previousGeometriesInViewportCount &&
        !this.previousGeometriesPartiallyInViewport;

      const dataInsideBoundariesNoNewBoundaryInViewport =
        !this.showDataOutsideBoundaries &&
        countGeometriesInViewport === this.previousGeometriesInViewportCount &&
        (this.lockedView ||
          this.viewInfo.viewportInfo.dataLevel === this.previousDataLevel) &&
        !this.previousGeometriesPartiallyInViewport;

      if (
        lockedDataZoomIn ||
        unlockedDataZoomInSameDataLevel ||
        dataInsideBoundariesZoomingOut ||
        dataInsideBoundariesNoNewBoundaryInViewport
      ) {
        response = false;
      }

      this.previousZoom = map.getZoom(); //update previous zoom value with current zoom level
      this.previousGeometriesInViewportCount = countGeometriesInViewport;
      this.previousGeometriesPartiallyInViewport =
        geometriesPartiallyInViewport;

      return response;
    },
    /**
     * check whether any geometries (custom areas/client boundaries) on map
     * are only partially visible in the viewport
     */
    checkGeometriesPartiallyInViewport() {
      let partialGeometries = false;

      //loop through geometries and determine whether only parts of their bounding boxes are visiblen viewport
      this.getGeometryBoundsVisibilityInfo().forEach((geomBoundingBox) => {
        let cornersInViewport = Object.values(geomBoundingBox.corners);
        //if four corners of bounding box don't match - geometry is partially in view
        if (
          geomBoundingBox.intersects === true &&
          !cornersInViewport.every((value) => value === true)
        ) {
          partialGeometries = true;
        }
      });

      return partialGeometries;
    },
    /** returns LatLngBounds object representing bounding box around geometry polygon*/
    getGeometryBoundingBoxCoords(geometry) {
      //calulate extremities of geometry bounding box
      let geomBounds = new google.maps.LatLngBounds();
      geometry.getPath().forEach((latLng) => {
        geomBounds.extend(latLng);
      });
      return geomBounds;
    },
    /**
     * return an array of objects indicating whether each geometry
     * (custom areas/client boundaries) bounding box intersects with the viewport and
     * which corners are within the map viewport (partially visible)
     */
    getGeometryBoundsVisibilityInfo() {
      if (this.polygonObjects.length > 0 || this.clientBoundary.length > 0) {
        let geometries = [...this.polygonObjects, ...this.clientBoundary];
        let viewport = map.getBounds();

        //loop through geometries and determine any parts of bounding box are in viewport
        let geometryBoundsVisibilityData = [];
        geometries.forEach((geom) => {
          let geomBounds = this.getGeometryBoundingBoxCoords(geom);
          let geomNorth = geomBounds.getNorthEast().lat();
          let geomEast = geomBounds.getNorthEast().lng();
          let geomSouth = geomBounds.getSouthWest().lat();
          let geomWest = geomBounds.getSouthWest().lng();
          geometryBoundsVisibilityData.push({
            corners: {
              northWest: viewport.contains(
                new google.maps.LatLng(geomNorth, geomWest),
              ),
              northEast: viewport.contains(
                new google.maps.LatLng(geomNorth, geomEast),
              ),
              southEast: viewport.contains(
                new google.maps.LatLng(geomSouth, geomEast),
              ),
              southWest: viewport.contains(
                new google.maps.LatLng(geomSouth, geomWest),
              ),
            },
            intersects: viewport.intersects(geomBounds),
          });
        });

        return geometryBoundsVisibilityData;
      }

      return []; //there are no geometries on map
    },
    /**
     * return a count of geometries (custom areas/client boundaries) that are
     * fully or partially visible in the google map viewport
     */
    countGeometriesInViewport() {
      let count = 0;
      //loop through geometries and check whether any intersect with viewport
      this.getGeometryBoundsVisibilityInfo().forEach((geomBoundingBox) => {
        if (geomBoundingBox.intersects === true) {
          count++;
        }
      });

      return count;
    },
    /** set data level based on selected level */
    setSelectedDataLevel() {
      //set data level to selected and reset flag
      if (!this.lockedView && this.selectedDataLevel) {
        this.previousDataLevel = this.viewInfo.viewportInfo.dataLevel;
        this.viewInfo.viewportInfo.dataLevel = this.selectedDataLevel;
        this.selectedDataLevel = null;
      }
    },
    /** set data level based on map zoom */
    setZoomDataLevel() {
      this.previousDataLevel = this.viewInfo.viewportInfo.dataLevel;
      if (
        //not locked & zoom is below data lowest level - set data level to lowest
        !this.lockedView &&
        this.viewInfo.indicatorInfo.lowest_show_level >
          this.zoomAreaLevels[map.getZoom()]
      ) {
        this.viewInfo.viewportInfo.dataLevel =
          this.viewInfo.indicatorInfo.lowest_show_level;
      } else {
        //not locked - set data level to zoom
        if (!this.lockedView) {
          this.viewInfo.viewportInfo.dataLevel =
            this.zoomAreaLevels[map.getZoom()];
        }
      }
    },
    async mapSetup() {
      if (!this.savedMapId) {
        // If not loading a saved map, load default view and boundary
        this.centreToDefaultView();
      }

      // load the client boundary and show it on the map if show_boundary is true
      await this.loadClientBoundary();
      if (this.$store.getters.customClientConfig.show_boundary) {
        this.clientBoundary.forEach((polygon) => {
          toRaw(polygon).setMap(map);
        });
        this.mapBoundariesGeoJson.boundaries = this.clientBoundaryGeoJson;
      }

      // get the service icons
      this.loadServiceTypeIcons();

      // add listener to see if anything changes
      map.addListener(
        "zoom_changed",
        () => {
          this.deBounce({ event: "zoom" });
        },
        { passive: true },
      );

      map.addListener(
        "drag",
        () => {
          this.deBounce({ event: "drag" });
        },
        { passive: true },
      );

      // set up all the view details
      this.viewInfo.viewportInfo = {
        dataLevel: this.viewInfo.viewportInfo.dataLevel
          ? this.viewInfo.viewportInfo.dataLevel
          : 6,
        xmin: 0,
        ymin: 0,
        xmax: 0,
        ymax: 0,
        ind_id: this.viewInfo.viewportInfo.ind_id
          ? this.viewInfo.viewportInfo.ind_id
          : null,
      };

      // get the viewport to start
      this.deBounce();
    },
    centreToDefaultView() {
      // get the clients default view
      const defaultZoom = this.$store.getters.customClientConfig.default_zoom;
      const defaultLat = this.$store.getters.customClientConfig.default_lat;
      const defaultLng = this.$store.getters.customClientConfig.default_lng;

      // if they have them ALL, set the map up to that
      if (defaultZoom && defaultLat && defaultLng) {
        this.firstLoad = true;
        this.hasDefaultView = true;
        if (!isNaN(defaultLat) && !isNaN(defaultLng)) {
          map.setZoom(defaultZoom);
          map.setCenter({
            lat: parseFloat(defaultLat),
            lng: parseFloat(defaultLng),
          });
        } else {
          console.error("Invalid coordinates for default view");
        }
      }
    },
    async loadClientBoundary() {
      this.loadingBoundary = true;
      // first clear out anything that's there
      if (this.clientBoundary.length > 0) {
        this.clientBoundary.forEach((polygon) => {
          toRaw(polygon).setMap(null);
        });

        this.clientBoundary = [];
      }

      // get the clients boundary
      if (this.$store.getters.customClientConfig.has_boundary) {
        const { Polygon } = await google.maps.importLibrary("maps");

        await this.$axios
          .get("/client-boundary")
          .then(
            function (response) {
              // build and store the polygon
              if (response.data.boundary) {
                const boundary = JSON.parse(response.data.boundary);

                // add client boundary to list of boundaries currently displayed
                this.clientBoundaryGeoJson = JSON.stringify(boundary);
                if (this.clientBoundaryGeoJson.length > 0) {
                  this.mapBoundariesGeoJson.boundaries =
                    this.clientBoundaryGeoJson;
                }

                // build an array of polygons, can be one, or many for a multipolygon. This doesn't draw it on the map yet
                let polygons = [];

                if (boundary.type === "Polygon") {
                  // Polygon
                  const googleMapsPaths = boundary.coordinates.map((ring) =>
                    ring.map((coord) => {
                      return { lat: coord[1], lng: coord[0] };
                    }),
                  );

                  polygons.push(
                    new Polygon({
                      paths: googleMapsPaths,
                      fillOpacity: 0,
                      strokeWeight: 5,
                      strokeColor: response.data.boundary_colour,
                      zIndex: 5,
                      clickable: false,
                    }),
                  );
                  this.clientBoundary = polygons;
                } else {
                  // MultiPolygon
                  const multiCoordinates = boundary.coordinates;
                  multiCoordinates.forEach((coords) => {
                    const googleMapsPaths = coords.map((coord) => {
                      return coord.map((subCoord) => {
                        return { lat: subCoord[1], lng: subCoord[0] };
                      });
                    });

                    polygons.push(
                      new Polygon({
                        paths: googleMapsPaths,
                        fillOpacity: 0,
                        strokeWeight: 5,
                        strokeColor: response.data.boundary_colour,
                        zIndex: 5,
                        clickable: false,
                      }),
                    );
                    this.clientBoundary = polygons;
                  });
                }
              }
              this.loadingBoundary = false;
            }.bind(this),
          )
          .catch(
            function (error) {
              // handle error
              console.error(error);
              this.loadingBoundary = false;
            }.bind(this),
          );
      }
    },
    deBounce({ event = "none" } = {}) {
      /**
       * force update in updateViewDetails if the map has been dragged and data is diplayed outside boundaries
       */
      const forceUpdate =
        event == "drag" && this.showDataOutsideBoundaries ? true : false;

      if (this.markers.legnth > 0) {
        this.loadingServices = true;
      }
      // clear this
      clearTimeout(this.idleTimeout);
      // start the countdown again!
      this.idleTimeout = setTimeout(
        function () {
          this.updateViewDetails({ forceUpdate: forceUpdate });
        }.bind(this),
        1250,
      );
    },
    getIndicatorDetails() {
      this.emit.emit("systemBusy", true);

      const fetchDataLevels = () => {
        return this.$axios
          .get("/list-area-data-levels")
          .then((response) => {
            this.dataLevels = response.data;
          })
          .catch((error) => {
            console.error("Error fetching data levels:", error);
          });
      };

      const fetchMetadataMap = () => {
        return this.$axios
          .get("/standard-metadata-map/" + this.selectedIndicator)
          .then((response) => {
            // handle success
            this.viewInfo.indicatorInfo = response.data[0];
            this.viewInfo.numeratorInfo = response.data[1];

            // Update zoomAreaLevels with restricted map agg levels if restrict agg on map is true
            if (this.viewInfo.indicatorInfo.restrict_agg_on_map) {
              try {
                this.mapAggregationLevels = JSON.parse(
                  this.viewInfo.indicatorInfo.map_aggregation_levels,
                );
                // Convert these level ids to levels
                this.mapAggregationLevels = this.mapAggregationLevels
                  .map((levelId) => {
                    const level = this.dataLevels.find(
                      (dataLevel) => dataLevel.id === levelId,
                    );
                    return level ? level.area_level : null;
                  })
                  .filter((level) => level !== null);

                // Set init data level to highest level in mapAggregationLevels in case it's lower than the default LA level
                this.viewInfo.viewportInfo.dataLevel = Math.max(
                  ...this.mapAggregationLevels,
                );
              } catch (e) {
                console.error("Failed to parse mapAggregationLevels:", e);
                return;
              }

              // Loop through zoomAreaLevels and replace levels not in mapAggregationLevels with
              // the next highest agg level that is in mapAggregationLevels
              for (let levelId in this.zoomAreaLevels) {
                let level = this.zoomAreaLevels[levelId];
                if (!this.mapAggregationLevels.includes(level)) {
                  let nextHighestLevel = this.mapAggregationLevels.find(
                    (aggLevel) => aggLevel > level,
                  );
                  if (nextHighestLevel !== undefined) {
                    this.zoomAreaLevels[levelId] = nextHighestLevel;
                  } else {
                    // If no higher level is found, use the highest level in mapAggregationLevels
                    this.zoomAreaLevels[levelId] = Math.max(
                      ...this.mapAggregationLevels,
                    );
                  }
                } else if (level > Math.max(...this.mapAggregationLevels)) {
                  // If level is higher than the max in mapAggregationLevels, clamp it down to the highest
                  this.zoomAreaLevels[levelId] = Math.max(
                    ...this.mapAggregationLevels,
                  );
                }
              }
              //console.log("Updated zoomAreaLevels:", this.zoomAreaLevels);
            } else {
              // Restore original zoomAreaLevels and mapAggregationLevels if restrict_agg_on_map is false
              this.zoomAreaLevels = { ...this.defaultZoomAreaLevels };
              this.mapAggregationLevels = [...this.defaultMapAggregationLevels];
            }
            this.colourScheme = response.data[0].colour_scheme;
            this.getDataCount();
            this.getPolygonsWithDataset();
          })
          .catch((error) => {
            // handle error
            console.error(error);
            this.emit.emit("systemBusy", false);
          });
      };

      // Promise chain: fetch data levels if needed, then fetch metadata map
      const dataLevelsPromise =
        Object.keys(this.dataLevels).length === 0
          ? fetchDataLevels()
          : Promise.resolve();
      dataLevelsPromise.then(fetchMetadataMap);
    },
    /* Currently IS NOT BEING USED. 
       Add event listener to highlight the boundaries of the map when hovering over custom areas
    */
    addCustomAreaListener(polygon) {
      const customAreaID = polygon.customAreaID;

      // mouse over
      polygon.addListener("mouseover", () => {
        // highlight the boundary
        this.setCABoundaryStyle(polygon, "#0E5B99", 100, 5);

        // highlight the area in the legend
        this.$refs.customAreaLegend.highlightArea(customAreaID);
      });

      // mouse out
      polygon.addListener("mouseout", () => {
        this.setCABoundaryStyle(polygon, "#000000", 1, 3);

        this.$refs.customAreaLegend.removeAreaHighlight(customAreaID);
      });

      // when clicked scroll to the area in the legend
      polygon.addListener("click", () => {
        this.$refs.customAreaLegend.scrollToArea(customAreaID);
      });
    },
    async getCustomAreasPolygon(selectedCustomAreas) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(
            `/custom-areas-polygon-with-services/${this.showOuterCustomAreas}`,
            selectedCustomAreas,
          )
          .then((response) => {
            // store the service ids for the custom areas
            const serviceIds = Object.values(response.data.service_ids);
            this.customAreaServiceIds = [
              ...this.customAreaServiceIds,
              ...serviceIds,
            ];

            const geojson = JSON.parse(response.data.geojson);
            const features = geojson?.features || [geojson];

            //add custom area geometries to list of boundaries currently displayed
            this.mapBoundariesGeoJson.customareas = features.map((f) =>
              JSON.stringify(f.geometry),
            );

            // turn each returned feature into a google maps object and load onto the map
            this.updatePolygonObjects(features).then(() => {
              // zoom to the areas
              if (!this.savedMapId) {
                this.zoomToAreas();
              }
              // if we have markers loaded, rerender to filter by custom areas
              if (this.markers.length > 0) {
                this.loadSelectedTypes(false);
              }
            });
            this.emit.emit("systemBusy", false);
            resolve();
          })
          .catch((error) => {
            // handle error
            console.error(error);
            this.emit.emit("systemBusy", false);
            this.emit.emit("systemMessage", {
              title: "Failed to load boundary",
              message: error?.response?.data?.message,
              timeout: -1,
              colour: "error",
            });
            reject(error);
          });
      });
    },
    handleCustomAreaViewing(selectedCustomAreas) {
      this.emit.emit("systemBusy", true);
      this.customAreaServiceIds = [];
      this.selectedCustomAreas = [...selectedCustomAreas];
      if (selectedCustomAreas.length === 0) {
        if (this.markers.length > 0) {
          this.loadSelectedTypes();
        }
        this.emit.emit("systemBusy", false);
        return;
      }

      this.getCustomAreasPolygon(selectedCustomAreas);
    },
    async handleToggleOuterBoundareis(selectedCustomAreas) {
      await this.getCustomAreasPolygon(selectedCustomAreas);

      return true;
    },
    async updatePolygonObjects(features) {
      await this.removePolygons();
      const { Polygon } = await google.maps.importLibrary("maps");

      features.forEach((feature) => {
        const geometry = feature.geometry;
        const geometryType = geometry.type;

        if (geometryType === "Polygon") {
          // Polygon with possible holes
          const googleMapsPaths = geometry.coordinates.map((ring) =>
            ring.map((coord) => {
              return { lat: coord[1], lng: coord[0] };
            }),
          );

          const googleMapsPolygon = new Polygon({
            map,
            paths: googleMapsPaths,
            customAreaID: feature.properties?.custom_area_id ?? null,
            fillOpacity: 0,
            strokeWeight: 3,
            strokeColor: "#000000",
            zIndex: 10,
            clickable: false,
          });

          // this.addCustomAreaListener(googleMapsPolygon);

          // add custom props
          this.polygonObjects.push(googleMapsPolygon);
        } else if (geometryType === "MultiPolygon") {
          // MultiPolygon
          const multiCoordinates = geometry.coordinates;
          multiCoordinates.forEach((coords) => {
            const googleMapsPaths = coords.map((coord) => {
              return coord.map((subCoord) => {
                return { lat: subCoord[1], lng: subCoord[0] };
              });
            });

            const googleMapsPolygon = new Polygon({
              map,
              paths: googleMapsPaths,
              customAreaID: feature.properties?.custom_area_id ?? null,
              fillOpacity: 0,
              strokeWeight: 3,
              strokeColor: "#000000",
              zIndex: 10,
              clickable: false,
            });

            // this.addCustomAreaListener(googleMapsPolygon);

            // add custom props
            this.polygonObjects.push(googleMapsPolygon);
          });
        }
      });
    },
    zoomToAreas() {
      // gather up all the bounds of any loaded polys
      var bounds = new google.maps.LatLngBounds();

      // iterate over each polygon in this.polygonObjects and extend the bounds
      this.polygonObjects.forEach(function (polygon) {
        polygon.getPath().forEach(function (latLng) {
          bounds.extend(latLng);
        });
      });

      // fit the map to the bounds to show all the polygons
      map.fitBounds(bounds);
    },
    zoomToPoints() {
      // Check if there are markers to zoom to
      if (this.markers.length > 0) {
        // get the bounds of the points
        var bounds = new google.maps.LatLngBounds();

        // get the extent of visible markers
        this.markers.forEach(function (marker) {
          bounds.extend(marker.position);
        });

        // fit the map to show them
        map.fitBounds(bounds);
      }
    },
    updateSelectedIndicator(value) {
      this.selectedIndicator = value;
      this.viewInfo.viewportInfo.ind_id = value;
      if (this.viewInfo.viewportInfo.ind_id) {
        this.getIndicatorDetails();
      }

      // clear selected area
      this.areaMouseOverInfo.areaInfo = null;
      this.areaMouseOverInfo.freeze = false;
    },
    async removePolygons() {
      this.polygonObjects.forEach((polygon) => {
        toRaw(polygon).setMap(null);
      });
      this.polygonObjects = [];
    },
    getRandomOffset() {
      return (Math.random() - 0.5) * 0.00005;
    },
    sendAnalytics(event) {
      this.$axios.put("/send-analytics", {
        event: event,
        moduleName: this.$route.path,
        pageName: this.$route.name,
      });
    },
    activateTooltip(event) {
      //A11y OI-1140 - listen for key press
      let el = event.currentTarget;
      el.addEventListener("keyup", this.deactivateToolipOnEsc);
      //maintain showTooltip reactivity by cloning and copying back with key
      let showTooltipClone = { ...this.showTooltip };
      showTooltipClone[event.currentTarget.id] = true;
      this.showTooltip = showTooltipClone;
    },
    deactivateTooltip(event) {
      //A11y OI-1140 - remove focus and listener
      let el = event.currentTarget;
      el.removeEventListener("keyup", this.deactivateToolipOnEsc);
      //maintain showTooltip reactivity by cloning and copying back with key
      let showTooltipClone = { ...this.showTooltip };
      showTooltipClone[event.currentTarget.id] = false;
      this.showTooltip = showTooltipClone;
    },
    deactivateToolipOnEsc(event) {
      //A11y OI-1140
      if (event.key === "Escape") {
        //hide tooltip if escape ket pressed while hovering
        this.showTooltip[event.currentTarget.id] = false;
      }
    },
    setCABoundaryStyle(
      polygon,
      color = "#000000",
      zIndex = 10,
      strokeWeight = 3,
    ) {
      polygon.setOptions({
        strokeColor: color,
        zIndex: zIndex,
        strokeWeight: strokeWeight,
        // USE below only if addCustomAreaListener is used
        // clickable:
        //   this.showOuterCustomAreas || this.viewInfo.viewportInfo?.ind_id
        //     ? false
        //     : true,
      });
    },
    highlightCABoundary(areaID) {
      if (this.showOuterCustomAreas) return;

      this.polygonObjects.forEach((polygon) => {
        if (polygon.customAreaID === areaID) {
          this.setCABoundaryStyle(polygon, "#0E5B99", 1000, 5);
        }
      });
    },
    removeHighlightCABoundary(areaID) {
      if (this.showOuterCustomAreas) return;

      this.polygonObjects.forEach((polygon) => {
        if (polygon.customAreaID === areaID) {
          this.setCABoundaryStyle(polygon, "#000000", 10, 3);
        }
      });
    },
  },
  watch: {
    // When boundaries are set to show, put them on the google map, otherwise remove them
    boundaryFlag() {
      if (!this.boundaryFlag) {
        this.clientBoundary.forEach((polygon) => {
          toRaw(polygon).setMap(null);
        });
        delete this.mapBoundariesGeoJson.boundaries;
      } else if (this.boundaryFlag) {
        this.clientBoundary.forEach((polygon) => {
          toRaw(polygon).setMap(map);
        });
        this.mapBoundariesGeoJson.boundaries = this.clientBoundaryGeoJson;
      }
    },
    hotspotFlag() {
      if (this.viewInfo.indicatorInfo.id) {
        this.getPolygonsWithDataset();
        this.buildIndicatorQuintiles(true);
      }
    },
    navigateResult: function () {
      map.fitBounds(this.navigateResult);
      //map.setZoom(13);
    },
    selectedCustomAreas: function () {
      if (this.selectedCustomAreas.length === 0) {
        this.removePolygons();
        if (this.markers.length > 0) {
          this.loadSelectedTypes();
        }
        delete this.mapBoundariesGeoJson.customareas;
      }
    },
    mapBoundariesGeoJson: {
      deep: true,
      handler: function (newVal) {
        /**
         * if all boundaries and custom areas are hidden then
         * set the map to show data outside boundaries and reload
         * polygons
         **/
        if (Object.keys(newVal).length === 0) {
          this.showDataOutsideBoundaries = true;
          if (this.selectedIndicator !== null) {
            this.getPolygonsWithDataset();
          }
        }
        /**
         * fetch the map polygons if boundaries or customareas change
         * and we're only displaying data inside boundaries
         */
        if (this.showDataOutsideBoundaries === false) {
          if (this.selectedIndicator !== null) {
            this.getPolygonsWithDataset();
          }
        }
      },
    },
    displayServicesOutsideCustomAreas() {
      this.loadSelectedTypes(false);
    },
    areServicesClustered() {
      this.loadSelectedTypes(false);
    },
  },
};
</script>

<style scoped>
.mapButton {
  color: white;
  background-color: #607d8b !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.mapButtons {
  position: absolute !important;
  top: 75px;
  background-color: rgba(243, 237, 237, 0);
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 25px;
  gap: clamp(1px, 1vh, 10px);
}
@media only screen and (max-height: 700px) {
  .mapButton {
    width: 7vh !important;
    height: 7vh !important;
    font-size: 2vh;
  }
}
@media only screen and (min-height: 701px) {
  .mapButton {
    width: 7vh !important;
    max-width: 72px !important;
    height: 7vh !important;
    max-height: 72px !important;
    font-size: clamp(1px, 1.75vh, 18px);
  }
}

.mapLegend {
  position: absolute !important;
  top: 10px;
  right: 20px;
  margin-right: 5px;
  z-index: 1;
  width: 450px;
  max-width: 450px;
  max-height: 92vh !important;
  overflow-y: auto;
}

/** move google map type control popouts above buttons at centre bottom */
#mapCanvas :deep(.gm-style-mtc ul) {
  position: absolute;
  left: 0px !important;
  top: -40px !important;
}
</style>
