<template>
  <v-app
    id="inspire"
    v-if="config.siteConfig.hasOwnProperty('page_title')"
    :style="loggedIn ? 'background-color: #ffffff!important;' : ''"
  >
    <IntercomWidget />
    <ReportViewer />
    <v-navigation-drawer
      v-if="loggedIn"
      v-model="drawer"
      app
      :style="!loggedIn ? 'display:none' : null"
      width="400"
      :inert="!drawer"
    >
      <v-list-item v-if="loggedIn" link class="text-left pa-4">
        <!--   Public site  -->
        <div v-if="config.siteConfig.is_public_site">
          <v-list-item-title class="text-h6">
            {{ this.config.customClientConfig.client_name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ this.config.siteConfig.tag_line }}
          </v-list-item-subtitle>
        </div>
        <!--  normal site  -->
        <span v-else>
          <v-list-item-title class="text-h6">
            {{ this.config.userProfile.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="pt-1">
            {{ this.config.userProfile.email }}
          </v-list-item-subtitle>
          <v-list-item-subtitle class="pt-4">
            {{ this.config.customClientConfig.client_name }}
          </v-list-item-subtitle>
        </span>
        <!-- demo expiry card -->
        <v-card
          theme="localInsightLightTheme"
          variant="flat"
          class="bg-inverted-warning on-inverted-warning mt-2"
        >
          <v-card-text
            v-if="
              this.config.customClientConfig.client_type == 'demo' &&
              this.config.customClientConfig.demo_end_date
            "
          >
            <div class="font-weight-medium" v-if="demoDaysRemaining > 1">
              Your trial period will end in {{ demoDaysRemaining }} days
            </div>
            <div class="font-weight-medium" v-else>
              Your trial period ends today
            </div>
            <div>Some functionality is restriced during the trial</div>
          </v-card-text>
        </v-card>
        <!-- Show client selector if there is more than one choice -->
        <v-list-item-subtitle v-if="otherClientsCount">
          <br />
          <v-autocomplete
            v-model="switchClient"
            :items="config.userConfig.otherClients"
            placeholder="Switch to a different account"
            id="switchClient"
            no-data-text="No clients found"
            item-title="client_name"
            return-object
            focused
            tabindex="1"
            variant="outlined"
            single-line
            density="compact"
            rounded="0"
            @update:modelValue="switchToDifferentClient()"
            autocomplete="off"
          ></v-autocomplete>
        </v-list-item-subtitle>
        <v-list v-if="config.otherSites.length">
          <v-list-subheader>You can also visit these sites:</v-list-subheader>
          <div
            v-for="(item, i) in config.otherSites"
            :key="i"
            class="otherLinks ml-5 py-2"
            @click="visitSite(item.url)"
            tabindex="1"
          >
            {{ item.name }}
          </div>
        </v-list>
      </v-list-item>

      <v-divider></v-divider>
      <v-list nav lines="one" id="navList" class="text-left moduleList">
        <v-list-item
          v-for="item in config.userConfig.modules"
          link
          :key="item.slug"
          :id="item.label"
          @click="router(item.route)"
          tabindex="1"
          :title="item.label"
          class="mb-0"
        >
          <template v-slot:prepend>
            <v-icon :icon="'mdi-' + item.icon"></v-icon>
          </template>
          <template v-slot:append>
            <OcsiOnlyChip v-if="item.ocsiOnly"></OcsiOnlyChip>
            <!-- temp chip beta chip for datatsore module - remove when full release -->
            <BetaChip v-if="item.name == 'datastore'"></BetaChip>
          </template>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>
      <template v-slot:append>
        <div>
          <v-list nav lines="one" class="text-left extraModuleList">
            <v-list-item
              v-if="uatEnvironment"
              link
              :href="pilotBugTrackerLink"
              target="_blank"
              class="mb-0"
            >
              <template v-slot:prepend>
                <v-icon icon="mdi-face-agent"></v-icon>
              </template>
              <v-list-item-title>Bug reporter</v-list-item-title>
            </v-list-item>
            <v-list-item
              class="mb-0"
              link
              href="https://localinsight.org/blog/"
              target="_blank"
              tabindex="1"
            >
              <template v-slot:prepend>
                <v-icon icon="mdi-newspaper-variant-outline"></v-icon>
              </template>
              <v-list-item-title>News</v-list-item-title>
            </v-list-item>
            <v-list-item
              id="help-centre-item"
              class="mb-0"
              link
              href="https://support.localinsight.org"
              target="_blank"
              tabindex="1"
            >
              <template v-slot:prepend>
                <v-icon icon="mdi-lifebuoy"></v-icon>
              </template>
              <v-list-item-title>Help Centre</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="!config.userProfile.is_public_site_user_profile"
              link
              href="https://support.localinsight.org/en/collections/623779-getting-help"
              target="_blank"
              tabindex="1"
            >
              <template v-slot:prepend>
                <v-icon icon="mdi-human-greeting-proximity"></v-icon>
              </template>
              <v-list-item-title>Contact</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              href="https://localinsight.org/accessibility-statement/"
              target="_blank"
              aria-label="accessibility statement"
              tabindex="1"
            >
              <template v-slot:prepend>
                <v-icon icon="mdi-human"></v-icon>
              </template>
              <v-list-item-title>Accessibility Statement</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="!config.userProfile.is_public_site_user_profile"
              @click="logout"
              class="text-red"
              tabindex="1"
            >
              <template v-slot:prepend>
                <v-icon icon="mdi-logout-variant"></v-icon>
              </template>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="config.userProfile.is_public_site_user_profile"
              @click="router('login')"
            >
              <template v-slot:prepend>
                <v-icon icon="mdi-logout-variant"></v-icon>
              </template>
              <v-list-item-title>Sign In</v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar
      density="compact"
      app
      :style="
        loggedIn
          ? 'background-color: ' + this.config.siteConfig.toolbar_colour
          : 'display:none'
      "
      id="appBar"
    >
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        :color="titleTextColor"
        id="navMenu"
        tabindex="1"
        title="Navigation"
      ></v-app-bar-nav-icon>
      <v-img
        class="ml-2"
        max-height="40"
        max-width="178"
        contain
        :src="config.siteConfig.logo"
        @click="router('map')"
        style="cursor: pointer"
        title="Map"
        alt="Company Logo"
      ></v-img>
      <EnvironmentTag />
      <v-row
        v-if="!isMobile"
        class="h1 pl-4 ml-1 text-left dynamicTextColour clientName"
      >
        <span
          v-if="this.config.customClientConfig.client_name"
          aria-label="Client Name"
          >{{ this.config.customClientConfig.client_name }}</span
        >
        <span v-else aria-label="Client Name">{{
          this.config.siteConfig.name
        }}</span>
      </v-row>
      <v-spacer />
      <v-row
        align="center"
        justify="end"
        class="mr-1"
        style="text-align: right"
      >
        <v-col v-if="reportDownloaded || reportDownloading">
          <v-row align-content="end" no-gutters>
            <v-col cols="9" class="pr-4 pt-1 text-white">
              <v-progress-linear
                v-if="!reportDownloaded"
                v-model="progressLinear"
                color="success"
                height="25"
                striped
              >
                <div
                  v-if="reportLoadingProgress < 100"
                  class="font-weight-medium"
                >
                  <strong
                    >Loading Themes {{ Math.ceil(progressLinear) }}%</strong
                  >
                </div>
                <div v-else class="font-weight-medium">
                  <strong>Aggregating {{ Math.ceil(progressLinear) }}%</strong>
                </div>
              </v-progress-linear>
            </v-col>
            <v-col cols="3">
              <v-btn
                color="success"
                tile
                variant="elevated"
                @click="openReport"
                title="view report"
                aria-label="View Report"
                :disabled="!reportDownloaded"
              >
                {{ !reportDownloaded ? "Wait" : "Report" }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <!--   Extra pages text buttons  -->
        <v-card-actions v-if="config.siteConfig.is_public_site">
          <div v-for="page in config.userConfig.modules" :key="page.route">
            <v-btn
              v-if="
                page.hasOwnProperty('show_at_header') && page.show_at_header
              "
              variant="text"
              @click="router(page.route)"
              :style="'color: ' + titleTextColor"
              class="pa-3"
            >
              {{ page.label }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-row>

      <!-- <v-toolbar-title>Application</v-toolbar-title> -->
    </v-app-bar>

    <v-main
      id="mainApp"
      :style="!loggedIn ? 'padding:0px!important' : null"
      style="overflow: hidden"
    >
      <v-snackbar
        theme="localInsightLightTheme"
        id="ocsi-snackbar"
        class="mb-6"
        :class="snackbar?.textColour ?? 'text-white'"
        style="z-index: 100000001"
        v-model="snackbarVisible"
        :color="snackbar.colour"
        :location="snackbar.location"
        :rounded="snackbar.rounded"
        :timeout="snackbar.timeout"
        :multi-line="snackbar.rounded !== 'pill'"
      >
        <!-- pill snackbar title. -->
        <div
          v-if="snackbar.title && snackbar.rounded === 'pill'"
          :style="this.snackbar.width ? 'width: ' + this.snackbar.width : ''"
          :id="snackbar.id"
          :class="snackbar?.textColour ?? 'text-white'"
        >
          <v-icon v-if="snackbar.icon" size="15" class="mr-2">{{
            snackbar.icon
          }}</v-icon>
          {{ snackbar.title }}
          <span v-if="snackbar.rounded === 'pill' && snackbar.showClose">
            <v-btn
              tile
              variant="text"
              slim
              size="small"
              :style="{ 'min-width': 0 }"
              width="10"
              @click="snackbarVisible = false"
              aria-label="Close"
              >x</v-btn
            >
          </span>
        </div>
        <!-- regular snackbar title -->
        <div
          v-if="snackbar.title && snackbar.rounded !== 'pill'"
          class="text-h6 pb-3"
          :class="snackbar?.textColour ?? 'text-white'"
          :style="this.snackbar.width ? 'width: ' + this.snackbar.width : ''"
          :id="snackbar.id"
        >
          <v-icon v-if="snackbar.icon" class="pr-3 mr-2">{{
            snackbar.icon
          }}</v-icon>
          {{ snackbar.title }}
        </div>
        <v-row>
          <!-- message -->
          <v-col :cols="snackbar?.contactSupportBtn ? 8 : 12">
            <div
              v-if="snackbar.message"
              class="text-subtitle-1 pb-2 ml-10"
              :class="snackbar?.textColour ?? 'text-white'"
              id="snackBarMessage"
              v-html="snackbar.message"
            ></div>
          </v-col>
          <v-col v-if="snackbar?.contactSupportBtn" cols="4" align-self-center>
            <!-- contact support button -->
            <div>
              <v-btn
                :class="snackbar?.textColour ?? 'text-white'"
                tile
                variant="text"
                :href="'mailto:support@ocsi.co.uk?subject=' + snackbar.title"
                aria-label="Contact Support"
              >
                Contact Support
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <!-- close button -->
        <div
          v-if="snackbar.rounded !== 'pill' && snackbar.showClose"
          style="width: 100%; text-align: right"
        >
          <v-btn
            :class="snackbar?.textColour ?? 'text-white'"
            tile
            variant="text"
            @click="snackbarVisible = false"
            aria-label="Close"
            >Close</v-btn
          >
        </div>
        <div
          v-if="snackbar.rounded !== 'pill' && snackbar.button"
          style="width: 100%; text-align: right"
        >
          <v-btn
            :class="snackbar?.textColour ?? 'text-white'"
            tile
            variant="text"
            @click="snackbar.button.callback()"
            aria-label="Close"
            ><div v-html="snackbar.button.text"></div>
          </v-btn>
        </div>
      </v-snackbar>
      <div class="progressBar" :style="!showLoader ? 'display:none;' : null">
        <v-progress-linear
          indeterminate
          color="#8DC8F1"
          height="6"
        ></v-progress-linear>
      </div>
      <div class="antiMasher" :style="!busy ? 'display:none' : null"></div>
      <router-view />
    </v-main>

    <MobileDevicesNotSupportedMessage
      v-if="
        isMobile && loggedIn && !config.userProfile.is_public_site_user_profile
      "
      :isMobile="isMobile"
    />
  </v-app>
  <v-app v-else-if="isSuspendedOnProduction">
    <v-alert text prominent color="#2196F3">
      <h2 class="text-black" style="font-family: Inter, sans-serif">
        Sorry but it appears this account is inactive.
      </h2>
      <h3 class="text-black" style="font-family: Inter, sans-serif">
        Please contact support@ocsi.co.uk if you require more information.
      </h3>
    </v-alert>
  </v-app>
  <v-app v-else-if="notSigned" style="background-color: #f9f9f9">
    <TermsAndConditions @logout="logout" />
  </v-app>
  <v-app v-else>
    <div class="loadingLI">
      <v-progress-circular :size="200" :width="3" color="#51627C" indeterminate
        >Loading Local Insight</v-progress-circular
      >
    </div>
  </v-app>
</template>

<script>
import ReportViewer from "@/components/ReportViewer";
import OcsiOnlyChip from "@/components/OcsiOnlyChip";
import BetaChip from "@/components/BetaChip";
import { getURLParam } from "@/mixins/GetURLParam";
import { getCookie } from "@/mixins/GetCookie";
import { setCookie } from "@/mixins/SetCookie";
import { isMobileDevice } from "@/mixins/IsMobileDevice";
import { securedRoutes } from "./router/index.js";
import EnvironmentTag from "./components/EnvironmentTag.vue";
import MobileDevicesNotSupportedMessage from "./components/MobileDevicesNotSupportedMessage.vue";
import IntercomWidget from "@/components/IntercomWidget";
import TermsAndConditions from "@/components/TermsAndConditions";

export default {
  computed: {
    showLoader: {
      get() {
        return this.showProgressLoader && this.busy;
      },
    },
    headerNavList: {
      get() {
        const filteredObj = {};
        for (const key in this.config.userConfig.modules) {
          if (
            // eslint-disable-next-line no-prototype-builtins
            this.config.userConfig.modules.hasOwnProperty(key) &&
            this.config.userConfig.modules[key]["show_at_header"] === true
          ) {
            filteredObj[key] = this.config.userConfig.modules[key];
          }
        }
        return filteredObj;
      },
    },
    helpCenterLink() {
      let link;
      const isAustralia = this.config.siteConfig.site_country === "aus";
      const isPublicSite = this.config.userProfile.is_public_site_user_profile;

      if (isAustralia) {
        link = isPublicSite
          ? "auslocalinsight.zendesk.com/hc/en-gb/categories/21501045295517-Public-Sites"
          : "auslocalinsight.zendesk.com/hc/en-gb";
      } else {
        link = isPublicSite
          ? "local-insight-public.zendesk.com/hc/en-gb"
          : "local-insight.zendesk.com/hc/en-gb";
      }

      return link;
    },
    progressLinear: {
      get() {
        return this.reportLoadingProgress < 100
          ? this.reportLoadingProgress
          : this.aggregationProgressPercentage;
      },
    },
    reportDownloading: {
      get() {
        return this.$store.state.reportDownloading;
      },
      set(value) {
        this.$store.commit("setReportDownloading", value);
      },
    },
    reportDownloaded: {
      get() {
        return this.$store.state.reportDownloaded;
      },
      set(value) {
        this.$store.commit("setReportDownloaded", value);
      },
    },
    viewReportDialog: {
      get() {
        return this.$store.state.viewReportDialog;
      },
      set(value) {
        this.$store.commit("setViewReportDialog", value);
      },
    },
    aggregationProgressPercentage: {
      get() {
        return this.$store.state.aggregationProgressPercentage;
      },
    },
    reportLoadingProgress: {
      get() {
        return this.$store.state.reportLoadingProgress;
      },
    },
    otherClientsCount: {
      get() {
        return this.config.userConfig.otherClients.length ? true : false;
      },
    },
    config: {
      get() {
        return this.$store.state.config;
      },
      set(value) {
        this.$store.commit("setConfig", value);
      },
    },
    busy: {
      get() {
        return this.$store.state.busy;
      },
      set(value) {
        this.$store.commit("setBusy", value);
      },
    },
    favicon: {
      get() {
        return this.config.siteConfig.favicon;
      },
    },
    uatEnvironment() {
      return process.env.VUE_APP_ENVIRONMENT === "uat";
    },
    isOcsiMember: {
      get() {
        return this.$store.state.isOcsiMember;
      },
      set(value) {
        this.$store.commit("setIsOcsiMember", value);
      },
    },
    isGroupAdmin: {
      get() {
        return this.$store.state.isGroupAdmin;
      },
      set(value) {
        this.$store.commit("setIsGroupAdmin", value);
      },
    },
    isPowerUser: {
      get() {
        return this.$store.state.isPowerUser;
      },
      set(value) {
        this.$store.commit("setIsPowerUser", value);
      },
    },
    isViewReports: {
      get() {
        return this.$store.state.isViewReports;
      },
      set(value) {
        this.$store.commit("setIsViewReports", value);
      },
    },
    isPublicUser: {
      get() {
        return this.$store.state.isPublicUser;
      },
      set(value) {
        this.$store.commit("setIsPublicUser", value);
      },
    },
    demoDaysRemaining: {
      get() {
        //demo end date at midnight
        const demoEnd = new Date(
          this.config.customClientConfig.demo_end_date,
        ).setUTCHours(0, 0, 0, 0);
        //current date at midnight
        const today = new Date().setUTCHours(0, 0, 0, 0);
        //compare dates and convert milliseconds to days
        return Math.floor((demoEnd - today) / (1000 * 60 * 60 * 24));
      },
    },
  },
  data: () => ({
    notSigned: false,
    isMobile: false,
    showProgressLoader: true,
    viewReportButtonLabel: false,
    snackbarVisible: false,
    adminEmail: null,
    adminPassword: null,
    adminDialog: false,
    switchClient: null,
    drawer: false,
    loggedIn: false,
    snackbar: {
      message: "this is a message this is a message this is a message",
      title: null,
      timeout: 4000,
      colour: null,
      icon: "mdi-alert-circle-outline",
      location: "top",
      rounded: "none",
      width: null,
    },
    pilotBugTrackerLink:
      "https://docs.google.com/forms/d/e/1FAIpQLSdjJ99BpDNswfS7F5_lDZLX9BiyEqQGSckAdzFOvIaZmVEKQg/viewform",
    pilotFeedbackLink:
      "https://docs.google.com/forms/d/e/1FAIpQLSfxUVEBolGYSfXsfsuaVttn8qk6W8ypSTz-mkB3A4d34SYQpQ/viewform",
    isSuspendedOnProduction: false,
    titleTextColor: "white",
    referrer: null,
  }),
  components: {
    ReportViewer,
    OcsiOnlyChip,
    BetaChip,
    EnvironmentTag,
    MobileDevicesNotSupportedMessage,
    IntercomWidget,
    TermsAndConditions,
  },
  watch: {
    favicon() {
      const favicon = document.getElementById("favicon");
      favicon.href = this.config.siteConfig.favicon;
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title = to?.name
          ? `${to.name} | Local Insight`
          : "Local Insight";
      },
    },
  },
  mounted() {
    this.isMobile = isMobileDevice(); // should prolly make this global in the vuex store
    const elHtml = document.getElementsByTagName("html")[0];
    elHtml.style.overflowY = "hidden";
    // listen out for these
    this.emit.on("systemBusy", this.systemBusy);
    this.emit.on("systemMessage", this.displaySnackbar);
    this.emit.on("closeSystemMessage", this.closeSnackbar);
    this.emit.on("getClientAndUserConfig", this.getClientAndUserConfig);
    // else you will always bee redirected to the same public site
    if (getCookie("OI_PUBLIC_SITE")) {
      document.cookie =
        "BEARER_TOKEN=; domain=.localinsight.org; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }
    /**
     * wait for the router to be initialized before continuing this setup
     * (fractional delay in serving initial route but we need to catch
     * referrer for deep links)
     */
    this.$router.isReady().then(() => {
      // get logged in user details
      if (getCookie("BEARER_TOKEN")) {
        // set this
        this.$axios.defaults.headers.common["Authorization"] =
          "Bearer " + getCookie("BEARER_TOKEN");
        // call this
        this.getClientAndUserConfig();
      } else {
        this.loggedIn = false;
        // get the site config
        this.$axios
          .get("/get-site-config")
          .then(
            function (response) {
              this.isSuspendedOnProduction = false;
              // handle success
              // jog on!
              this.config = response.data;
              // set this flag
              this.$store.state.displayOnsSource = JSON.parse(
                process.env.VUE_APP_DISPLAY_ONS_SOURCE,
              )[this.config.siteConfig.site_country];
              // check if this is a public site
              if (this.config.siteConfig.is_public_site) {
                this.setUpModules();

                //fetch an array of paths requiring authentication from router
                const authenticatedRoutes = securedRoutes.map(({ path }) => {
                  return path;
                });

                //if there is a referrer - check whether the path requires auth
                const authenticatedReferrerRoute = this.referrer
                  ? authenticatedRoutes.includes(
                      //match url subdirectory or root (/) if not exists
                      this.referrer.path.match(/^(\/[^/]+)/)?.[0] || "/",
                    )
                  : false;

                // set the bearer token cookie for 2 hours, add it to the axios auth header
                setCookie("BEARER_TOKEN", this.config.userConfig.token, 2);
                this.$axios.defaults.headers.common["Authorization"] =
                  "Bearer " + getCookie("BEARER_TOKEN");

                //deep linking public sites
                if (
                  this.referrer &&
                  this.referrer.path !== "/map" &&
                  authenticatedReferrerRoute
                ) {
                  /**
                   * there is a referrer url that isn't maps page and the
                   * redirect route requires authentication - send user to
                   * login page
                   */
                  this.$router.push({
                    path: "/login",
                  });
                } else if (this.referrer) {
                  /**
                   * There is a referrer that doesn't require authentication such
                   * as shared reports or maps that redirect the user to /map and
                   * pass querystring e.g. ?savedmaps=
                   */
                  this.$router.push({
                    path: "/map",
                    query: this.referrer.query,
                  });
                } else {
                  /**
                   * catch all - default to map if no referrer
                   */
                  this.$router.push("/map");
                }
                this.loggedIn = true;
              }
              if (typeof this.config.userProfile !== "undefined") {
                if (this.config.userProfile.is_public_site_user_profile) {
                  setCookie("OI_PUBLIC_SITE", true, 2);
                }
              } else {
                document.cookie =
                  "OI_PUBLIC_SITE=; domain=.localinsight.org; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
              }
              this.busy = false;

              this.setToolbarTextColour();
            }.bind(this),
          )
          .catch(
            function (error) {
              // handle error
              console.error(error);
              // if it's suspended on production then show the message
              if (error.response.data.errors[0].includes("suspended")) {
                this.isSuspendedOnProduction = true;
              }
              this.busy = false;
            }.bind(this),
          );

        // redirect to the login page if we're not already there
        if (this.$route.path !== "/login") {
          // forgotten password
          var fp = getURLParam("token");
          if (fp) {
            document.cookie =
              "BEARER_TOKEN=; domain=.localinsight.org; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            this.router("login?token=" + fp);
          } else {
            //store referrer e.g. path and params & redirect to login
            this.referrer = this.$route;
            this.router("login");
          }
        }
      }
    });
  },
  methods: {
    checkLegal(config) {
      if (config.userProfile.is_public_site_user_profile) {
        return true;
      } else if (
        config.userProfile.date_user_accepted_terms_of_service &&
        config.userProfile.date_user_accepted_privacy_policy
      ) {
        return true;
      } else {
        this.notSigned = true;
        // deleting this prevents the main app from loading in the dom
        delete this.config.siteConfig.page_title;
        console.info(
          "user has not accepted Accepted Terms Of Service And Privacy Policy",
        );
        return false;
      }
    },
    openReport() {
      this.systemBusy(true);
      if (!this.viewReportDialog) {
        this.viewReportDialog = true;
      }
      this.viewReportButtonLabel = false;
      this.systemBusy(false);
    },
    visitSite(url) {
      var port = location.port ? ":" + location.port : "";
      var redirectURL =
        location.protocol +
        "//" +
        process.env.VUE_APP_ENVIRONMENT_SUB_DOMAIN +
        url +
        port +
        "/#" +
        this.$route.path;
      window.location.replace(redirectURL);
    },
    switchToDifferentClient() {
      console.log(this.switchClient);
      this.$axios
        .get("/switch-client/" + this.switchClient.id)
        .then(
          function (response) {
            if (response.data) {
              var port = location.port ? ":" + location.port : "";
              var redirectURL =
                location.protocol +
                "//" +
                process.env.VUE_APP_ENVIRONMENT_SUB_DOMAIN +
                this.switchClient["url"] +
                port +
                "/#" +
                this.$route.path;
              // some clients will have the same url
              if (redirectURL === window.location.href) {
                location.reload();
              } else {
                window.location.replace(redirectURL);
              }
            } else {
              this.emit.emit("systemMessage", {
                title: "Error!",
                message: "You do not have access to this client",
                timeout: 6000,
                colour: "error",
              });
            }
          }.bind(this),
        )
        .catch(
          function (error) {
            // it would appear there is something wrong...probably an invalid bearer token
            console.error("invalid user details and or token" + error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              colour: "error",
            });
            this.logout();
          }.bind(this),
        );
    },
    router(path) {
      // if a path is http then redirect to that url
      if (path.indexOf("http") > -1) {
        window.open(path, "_blank");
        return;
      }
      // something different for public reports
      if (path === "public-reports") {
        if (this.$route.path !== "/map" && this.$route.path !== "/") {
          this.$router.push("/map");
          setTimeout(() => {
            document.getElementById("mapReportsButton").click();
          }, 500);
        } else {
          document.getElementById("mapReportsButton").click();
        }
      } else if (this.$route.path !== "/" + path) {
        this.$router.push("/" + path);
      }
      this.drawer = false;
    },
    /** fetch logged in user config and site info */
    getClientAndUserConfig(referrer) {
      this.$axios
        .get("/client-user-site-config")
        .then(
          function (response) {
            if (!this.checkLegal(response.data)) {
              return;
            }
            this.config = response.data;

            this.setUpModules();

            this.$store.state.displayOnsSource = JSON.parse(
              process.env.VUE_APP_DISPLAY_ONS_SOURCE,
            )[this.config.siteConfig.site_country];
            // catch this
            if (this.config.siteConfig.is_public_site) {
              // need to catch if this
              if (getURLParam("token")) {
                this.systemBusy(false);
                if (this.$route.path !== "/login") {
                  if (referrer) {
                    this.$router.push(referrer);
                  } else {
                    this.router("map");
                  }
                }
                return;
              }
            }
            // set permission level
            this.setPermissionLevel();

            // set the toolbar text colour depending on the background brightness
            this.setToolbarTextColour();
            // make sure we are using the correct url..if not then they will be redirected to the one assciated to their user account
            if (
              process.env.VUE_APP_ENVIRONMENT_SUB_DOMAIN +
                this.config.siteConfig.url !==
              window.location.hostname
            ) {
              var port = location.port ? ":" + location.port : "";
              const path = referrer ? referrer.fullPath : "/map";

              var redirectURL =
                location.protocol +
                "//" +
                process.env.VUE_APP_ENVIRONMENT_SUB_DOMAIN +
                this.config.siteConfig.url +
                port +
                "/#" +
                path;
              window.location.replace(redirectURL);
            } else {
              // re(set) the bearer token cookie for another 2 hours
              setCookie("BEARER_TOKEN", getCookie("BEARER_TOKEN"), 2);
              // set these
              this.systemBusy(false);
              this.loggedIn = true;
              // redirect to the members area
              if (this.$route.path === "/login") {
                //if referrer route obect passed in from login page then redirect to that
                if (referrer) {
                  this.$router.push(referrer);
                } else {
                  //catch all - if no referrer passed go to map page
                  this.router("map");
                }
              }
            }
          }.bind(this),
        )
        .catch(
          function (error) {
            // it would appear there is something wrong...probably an invalid bearer token
            console.error("invalid user details and or token" + error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              colour: "error",
            });
            this.logout();
          }.bind(this),
        );
    },
    // call this to log users out
    logout() {
      this.$store.commit("resetConfig");
      // set this
      this.systemBusy(true);
      // reset this
      this.loggedIn = false;
      // call this
      this.$axios
        .post("/logout")
        .catch(
          function (error) {
            // it would appear there is something wrong...probably an invalid bearer token
            console.error("(logout) invalid user details and or token" + error);
          }.bind(this),
        )
        .then(
          function () {
            // clear the bearer cookie
            document.cookie =
              "BEARER_TOKEN=; domain=.localinsight.org; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            //end intercom session
            this.emit.emit("intercom:shutdown");
            //reload app to login page
            this.$router.go();
          }.bind(this),
        );
    },
    setUpModules() {
      const userModules = this.config.userConfig.modules; // comes from the BE
      const storedRoutes = this.$router.getRoutes(); // what we currently have stored

      Object.values(userModules).forEach((module) => {
        const target = `/${module.route}`;

        // in the list of secured routes, find the one that matches the user's allowed module
        const securedModule = securedRoutes.find(
          (route) => route.path.replace(/\/:[^/?]+\??/g, "") === target,
        );

        if (
          securedModule &&
          !storedRoutes.some((route) => route.path === target) // check if the route is already stored
        ) {
          this.$router.addRoute(securedModule);
        }
      });

      this.$router.replace(this.$route.fullPath);
    },
    setPermissionLevel() {
      switch (this.config.userConfig.permission_level) {
        // ocsi big boss
        case 1:
          this.isOcsiMember = true;
          this.isGroupAdmin = true;
          this.isPowerUser = true;
          this.isViewReports = true;
          this.isPublicUser = true;
          break;
        // group admin
        case 2:
          this.isGroupAdmin = true;
          this.isPowerUser = true;
          this.isViewReports = true;
          break;
        // power user
        case 3:
          this.isPowerUser = true;
          this.isViewReports = true;
          break;
        // view reports
        case 4:
          this.isViewReports = true;
          break;
        // public user
        case 5:
          this.isPublicUser = true;
          break;
      }
    },
    // for api calls..this shows the progress bar and adds a transparent layer over the screen to stop any button mashing
    systemBusy(state) {
      this.busy = state;

      // if it's an object, then read it
      if (typeof state === "object") {
        this.busy = state.busy;
        this.showProgressLoader = state.showProgressLoader;
      } else {
        this.busy = state;
        this.showProgressLoader = true;
      }
    },
    // shows messages on the screen
    displaySnackbar(info) {
      info.message =
        "message" in info && info["message"] !== null ? info.message : null;
      info.title =
        "title" in info && info["title"] !== null ? info.title : null;
      if (info.message !== null && info.title === null) {
        info.title = info.message;
        info.message = null;
      }

      info.timeout =
        "timeout" in info && info["timeout"] !== null ? info.timeout : 4000;
      info.colour =
        "colour" in info && info["colour"] !== null ? info.colour : null;
      info.location =
        "location" in info && info["location"] !== null ? info.location : "top";
      info.rounded =
        "rounded" in info && info["rounded"] !== null ? info.rounded : "none";
      info.icon = "icon" in info ? info.icon : "mdi-alert-circle-outline";
      info.width = "width" in info ? info.width : "640px";
      info.showClose = "showClose" in info ? info.showClose : true;
      info.textColour = "textColour" in info ? info.textColour : "text-white";
      info.contactSupportBtn =
        "contactSupportBtn" in info ? info.contactSupportBtn : false;
      info.button =
        "button" in info &&
        info["button"] !== null &&
        info.button?.text &&
        info.button?.callback
          ? info.button
          : null;

      this.snackbarVisible = false;
      this.snackbar.message = info.message;
      this.snackbar.title = info.title;
      this.snackbar.timeout = info.timeout;
      this.snackbar.colour = info.colour;
      this.snackbar.icon = info.icon;
      this.snackbar.location = info.location;
      this.snackbar.rounded = info.rounded;
      this.snackbar.width = info.width;
      this.snackbar.showClose = info.showClose;
      this.snackbarVisible = true;
      this.snackbar.id = {
        green: "successSnackbar",
        success: "successSnackbar",
        error: "errorSnackbar",
        red: "errorSnackbar",
        warning: "warningSnackbar",
      }[info.colour];
      this.snackbar.textColour = info.textColour;
      this.snackbar.contactSupportBtn = info.contactSupportBtn;
      this.snackbar.button = info.button;
    },
    closeSnackbar() {
      this.snackbarVisible = false;
    },
    setToolbarTextColour() {
      let backgroundColor = this.$store.state.config.siteConfig.toolbar_colour;
      let r = parseInt(backgroundColor.substr(1, 2), 16);
      let g = parseInt(backgroundColor.substr(3, 2), 16);
      let b = parseInt(backgroundColor.substr(5, 2), 16);

      // measure brightness
      let brightness = (r * 299 + g * 587 + b * 114) / 1000;

      // Determine the text color based on the brightness
      this.titleTextColor = brightness > 174 ? "black" : "white";

      // Create a new style element
      let style = document.createElement("style");
      style.type = "text/css";

      // Add a CSS rule to the style element
      style.innerHTML = `
        .dynamicTextColour,
        .text-report-loading,
        .v-overlay__content > .v-card-title,
        .v-dialog > .v-overlay__content > .v-card > .v-toolbar > .v-toolbar__content,
        .v-overlay__content > .v-card > .v-card-title {
          color: ${this.titleTextColor};
        }
      `;

      // Append the style element to the head of the document
      document.getElementsByTagName("head")[0].appendChild(style);
    },
  },
};
</script>

<style lang="css">
body {
  font-family: "Inter", sans-serif !important;
}
.text-body-1,
.text-body-2,
.text-overline,
.text-caption,
.v-card-title,
.v-toolbar-title,
.v-toolbar__content,
.content,
.v-dialog,
.v-card-text,
.text-body-1,
.text-subtitle-1,
.v-application,
.v-overlay-container,
.text-h6,
.text,
.pb-2,
.text-h5 {
  font-family: "Inter", sans-serif !important;
}
.clientName {
  border-left: 1px solid #fff;
  font-weight: 400 !important;
}
.h1 {
  font-size: 1.25rem;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 600;
  letter-spacing: 0.0125em;
  line-height: 2rem;
}
.app-report {
  border: 1px solid red !important;
}
.loadingLI {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
/* allow whitespace in snackbar messages */
#snackBarMessage {
  white-space: pre-wrap;
}

/* slow the spinners*/
.v-progress-circular--indeterminate .v-progress-circular__overlay {
  animation:
    progress-circular-dash 2.6s ease-in-out infinite,
    progress-circular-rotate 2.6s linear infinite !important;
}
.otherLinks {
  color: #0645ad;
  text-decoration: underline;
  font-size: 13px !important;
}
.app {
  background-color: #ebf2f6 !important;
}

#inspire {
  animation: fadeInAnimation ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  overflow: hidden;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.antiMasher {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 10000000000;
}
.progressBar {
  position: fixed;
  width: 100vw;
  z-index: 10000;
}
#app {
  font-family: "Inter", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
h1 {
  color: #51627c;
  font-size: 20p;
  font-family: Inter, sans-serif !important;
}
h2 {
  color: #51627c;
  font-size: 16p;
  font-family: Inter, sans-serif !important;
}
h3 {
  color: #51627c;
  font-size: 14p;
  font-family: Inter, sans-serif !important;
}
p {
  color: black;
  font-size: 11p;
  font-family: Inter, sans-serif !important;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.min-accessible-size {
  font-size: 12pt;
}

/*this is for the get all button loader animation*/
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.moduleList .v-list-item--density-default.v-list-item--one-line {
  min-height: 45px;
}

.extraModuleList .v-list-item--density-default.v-list-item--one-line {
  min-height: 45px;
}
/* make the tip tap fullscreen mode appear under the LI header */
.vuetify-pro-tiptap-editor--fullscreen {
  padding: 50px !important;
}

.progressText {
  width: 100%;
  text-align: center;
  font-size: 14px;
  height: 300px;
}

.progress {
  margin-top: 150px;
  width: 100%;
}

/* view of the disabled elevated button */
.v-btn--disabled.v-btn--variant-elevated {
  background-color: #acacac !important;
}
.v-btn--disabled.v-btn--variant-elevated .v-btn__append,
.v-btn--disabled.v-btn--variant-elevated .v-btn__prepend,
.v-btn--disabled.v-btn--variant-elevated .v-btn__content {
  color: #000000 !important;
}
.v-btn--disabled.v-btn--variant-elevated .v-btn__overlay,
.v-btn--disabled.v-btn--variant-flat .v-btn__overlay {
  opacity: 0.105384615 !important;
}

/* green elevated button colour */
.v-btn--elevated.bg-success {
  background-color: #265928 !important;
}

/* overwrite the info alert styles */
.v-btn--elevated.bg-info {
  background-color: #0e5b99 !important;
}
.v-alert.bg-info,
.v-alert.text-info {
  background-color: #bfe0f8 !important;
  color: #093f6b !important;
}

/* Create a pseudo element for the gradient background before the text
   and stretch it to cover the whole help centre button. This is a 
   workaround for lack of support for transitions between linear-gradients */
#help-centre-item {
  position: relative;
  overflow: hidden;
}

#help-centre-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
      90deg,
      rgba(141, 200, 241, 1) 0%,
      rgba(194, 149, 252, 1) 100%
    ),
    linear-gradient(white, white);
  opacity: 0.5;
  transition: opacity 0.3s ease;
  z-index: -1;
}

#help-centre-item:hover::before {
  background: linear-gradient(
      90deg,
      rgba(141, 200, 241, 1) 0%,
      rgba(194, 149, 252, 1) 100%
    ),
    linear-gradient(white, white);
  opacity: 1;
}

/* make the expansion panel titles smaller */
.staticExpansionTitlePanel {
  max-height: 47px !important;
  min-height: 10px !important;
}

/* to avoid pure black */
.text {
  color: #4d4d4d;
}

/* allow pill snackbars to be smaller */
#ocsi-snackbar .v-snackbar__wrapper {
  min-width: 50px !important;
}

/* add more contrast to the background to see white content better */
.grayed-bg {
  background-color: #f9f9f9;
}
/* redure the rounded corners for the year picker buttons */
.v-date-picker-years__content .v-btn--rounded {
  border-radius: 5px;
}

.grayedBackground {
  background-color: #f9f9f9 !important;
}
.cardTitle {
  color: #4d4d4d !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}
.grayedText * {
  color: #4d4d4d !important;
}
.v-alert.bg-warning {
  color: #8b4c04 !important;
  background-color: #ffeaa5 !important;
}
.v-alert.bg-warning .mdi-information {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}
/* remove box shadow from chips */
.chips .v-chip--variant-elevated {
  box-shadow: none;
}

.prompt {
  border: solid 1px #c2c2c2;
  border-radius: 8px;
  color: #0e5b99;
  background: white;
}
.promptCard :hover {
  cursor: pointer;
  background-color: #bfe0f8;
}

/* chip style for color="warning"  */
.v-chip.bg-warning {
  background-color: #fdd9af !important;
  font-weight: 400;
}
.v-chip.bg-warning .v-chip__content {
  color: #4f2a00 !important;
}

.bg-inverted-warning {
  background-color: rgb(var(--v-inverted-warning)) !important;
}

.on-inverted-warning {
  color: rgb(var(--v-on-inverted-warning)) !important;
}
</style>
