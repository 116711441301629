<template>
  <v-card-text class="text-left pa-0 d-flex justify-center">
    <v-card-text class="pa-0" style="max-width: 1024px">
      <!-- Search box -->
      <div class="pa-0 my-4">
        <IndicatorSearch v-model:filters="filters" @search="debouncedSearch" />
      </div>
      <v-card class="my-2" elevation="0" style="background-color: #f9f9f9">
        <!-- Tags -->
        <IndicatorSearchTags
          v-model:tagsProp="filters.tags"
          @search="debouncedSearch"
          :filterBlackList="['coverage']"
        />

        <v-card-text class="py-0 px-2">
          <!-- Filters -->
          <v-row
            v-if="indicators?.length || fetchingIndicators"
            class="px-0 my-2 align-center"
          >
            <v-col cols="9" class="text-h6 text pb-0">
              {{
                fetchingIndicators
                  ? "Searching..."
                  : `We found ${totalResultsCount} results that match your query`
              }}
            </v-col>
            <v-col cols="3 pb-0">
              <v-select
                v-model="filters.sortBy"
                :items="[
                  { title: 'Ascending (Asc)', value: 'asc' },
                  { title: 'Descending (Desc)', value: 'desc' },
                ]"
                no-data-text="No filters available"
                hide-details
                rounded="0"
                style="background-color: white"
                @update:modelValue="search"
                label="Sort"
                variant="outlined"
                density="compact"
              />
            </v-col>
          </v-row>

          <!-- Suggested prompts -->
          <v-card-text
            v-show="
              (!indicators?.length || indicators.length == 0) &&
              !noResults &&
              !fetchingIndicators
            "
            class="pa-0"
          >
            <IndicatorSearchSuggestions
              v-model:filtersProp="filters"
              @search="debouncedSearch"
              getSuggestionsAPI="themes-indicator-search-suggestions"
            />
          </v-card-text>

          <!-- Fetched indicators -->
          <v-card-text v-if="indicators?.length" class="pa-0">
            <IndicatorCard
              v-for="indicator in indicators"
              :key="indicator.id"
              :indicator="indicator"
              :opened="false"
            >
              <template #firstColumn>
                <!-- Extra buttons -->
                <div class="d-flex pt-2">
                  <v-spacer />
                  <v-btn
                    color="success"
                    prepend-icon="mdi-plus"
                    @click="
                      selectedIndicator = indicator;
                      addIndicatorDialog = true;
                    "
                    variant="elevated"
                    rounded="0"
                    class="mr-2"
                  >
                    add to theme
                  </v-btn>
                </div>
              </template>
            </IndicatorCard>
          </v-card-text>

          <!-- Skeleton laoders for indicator cards. We can see them while searching and then we chuck them underneath for lazy loading -->
          <v-card-text
            v-if="
              fetchingIndicators ||
              (indicators?.length && !areAllIndicatorsFetched)
            "
            class="pa-0 paginatedSkeletons"
          >
            <IndicatorCard
              v-for="n in 5"
              :key="n"
              :loading="true"
              :opened="false"
            >
              <!-- Extra buttons loading -->
              <template #firstColumn>
                <div class="d-flex pt-2">
                  <v-spacer />
                  <v-btn
                    disabled
                    color="success"
                    prepend-icon="mdi-plus"
                    variant="elevated"
                    rounded="0"
                    class="mr-2"
                  >
                    add to theme
                  </v-btn>
                </div>
              </template>
            </IndicatorCard>
          </v-card-text>

          <!-- No results found -->
          <v-card-text v-if="noResults" class="pa-0 pt-2">
            <v-alert
              color="warning"
              icon="mdi-information"
              size="10px"
              prominent
              class="datastoreNoResults"
            >
              <div style="font-weight: 500">No search results found</div>
              <div>
                We were unable to find any search results for your query, please
                try again with a different parameters
              </div>
            </v-alert>
          </v-card-text>
        </v-card-text>
      </v-card>
    </v-card-text>

    <!-- Add indicator to themes -->
    <ThemesManagerSearchAddIndicator
      v-model:addIndicatorDialog="addIndicatorDialog"
      :selectedIndicator="selectedIndicator"
      @reloadThemesManager="() => this.$emit('reloadThemesManager')"
    />
  </v-card-text>
</template>
<script>
import IndicatorCard from "./IndicatorCard.vue";
import IndicatorSearchTags from "@/components/IndicatorSearchTags.vue";
import IndicatorSearch from "@/components/IndicatorSearch.vue";
import ThemesManagerSearchAddIndicator from "@/components/ThemesManagerSearchAddIndicator.vue";
import IndicatorSearchSuggestions from "@/components/IndicatorSearchSuggestions.vue";
import { systemMessages } from "@/mixins/SystemMessages";
import { useDisplay } from "vuetify";
import { Debounce } from "@/mixins/Debounce";

export default {
  name: "ThemesManagerSearch",
  components: {
    IndicatorCard,
    IndicatorSearchTags,
    IndicatorSearch,
    ThemesManagerSearchAddIndicator,
    IndicatorSearchSuggestions,
  },
  mixins: [systemMessages, Debounce],
  data: () => ({
    suggestedPrompts: [],
    pageHeight: useDisplay().height,
    indicators: [],
    filters: {
      searchString: "",
      tags: [],
      sortBy: "asc",
    },
    fetchingIndicators: false,
    areAllIndicatorsFetched: false,
    noResults: false,
    paginatedFetch: false,
    totalResultsCount: 0,
    addIndicatorDialog: false,
    selectedIndicator: null,
    abortController: null,
    debouncedSearch: null,
  }),
  computed: {},
  mounted() {
    this.filters.searchString = this.$route.query?.s || "";
    this.filters.sortBy = this.$route.query?.sort || "asc";
    this.filters.tags = JSON.parse(this.$route.query?.tags || "[]");

    // if anything is coming through the URL - run search
    if (this.filters.searchString || this.filters.tags?.length) {
      this.search();
    }

    /** seems overengineered - but required
     * it is possible in some circumstances to fire search multiple times in quick succession
     * resulting in duplicated results. An example is a short result list such as custom data
     * while scrolled to the bottom and another search to check for paginated results is fired
     * immediately.  A debounce swallows any rapid suplicate firings of query.
     **/
    this.debouncedSearch = this.debounce(this.search, 200);
  },
  methods: {
    async search(offset = 0) {
      /**
       * Seems over engineered but required - if an AJAX search request is 'pending'
       * and another search request is fired then cancel the previous request and submit the
       * new one.  An edge case, but if a tag is added and the query runs long enough for the
       * user to remove the tag before the results are returned then the initial query/wrong
       * outcome can be displayed.  Using the abort controller is a nice way to invalidate backend
       * requests and keep the frontend in sync.
       **/
      if (this.abortController) {
        this.abortController.abort("AbortError");
        this.fetchingIndicators = false;
      }

      //only search if there is a search string and/or filters set
      if (this.filters["searchString"] || this.filters.tags?.length) {
        if (!this.paginatedFetch) {
          this.indicators = [];
        }

        this.fetchingIndicators = true;
        this.noResults = false;

        this.filters.offset = offset;
        this.filters.limit = this.getAvailableSlotsForIndicators();

        try {
          //set up new abort signal for this request so it can be cancelled
          this.abortController = new AbortController();
          const signal = this.abortController.signal;

          const response = await this.$axios.post(
            "themes-manager-indicator-search",
            this.filters,
            { signal },
          );

          //set indicators and totals details
          if (!this.paginatedFetch) {
            this.indicators.push(...response.data.indicators);
            this.areAllIndicatorsFetched =
              response.data.areAllIndicatorsFetched;
            this.totalResultsCount = response.data.totalCount;
            this.noResults = !this.indicators?.length;
          }

          this.fetchingIndicators = false;

          //return a response so that paginated call knows search has finished
          return response;
        } catch (error) {
          if (error.code == "ERR_CANCELED") {
            console.log("Request canceled");
          } else {
            this.errorMsg({
              title: "There was an error searching indicators",
              message: error.response?.data?.message,
            });
          }
        }
      } else {
        this.noResults = false;
        this.indicators = [];
      }
    },
    async getPaginatedIndicators() {
      // do nothing if there are no more indicators to fetch || a search is in progress || there are no search params
      if (
        this.areAllIndicatorsFetched ||
        this.paginatedFetch ||
        (!this.filters["searchString"] && !this.filters.tags?.length)
      ) {
        return;
      }

      this.paginatedFetch = true;

      const offset = this.indicators?.length ?? 0;

      //trigger an indicator search with offset from current results
      try {
        const response = await this.debouncedSearch(offset);
        this.indicators.push(...response.data.indicators);
        this.areAllIndicatorsFetched = response.data.areAllIndicatorsFetched;
      } catch (error) {
        console.error(error);
      } finally {
        this.paginatedFetch = false;
      }
    },
    // Fetch indicators based on the user's display height
    getAvailableSlotsForIndicators() {
      const INDICATOR_CARD = 94;
      const freeSpace = this.pageHeight - 300;

      return Math.floor(freeSpace / INDICATOR_CARD); // plus extra
    },
  },
  watch: {
    filters: {
      handler(val) {
        //set querystring params when search/filter tags change
        this.$router.push({
          path: "/themes/search",
          query: {
            s: val.searchString,
            sort: val.sortBy,
            tags: JSON.stringify(val.tags),
          },
        });
      },
      deep: true,
    },
  },
};
</script>
<style scoped></style>
