<template>
  <div>
    <div class="text-h6 text pb-2">
      Not sure where to start? Try one of these:
    </div>
    <v-card-text class="promptCard pa-0">
      <v-card-actions
        v-for="(prompt, i) in suggestedPrompts"
        :key="i"
        class="prompt px-4 mb-6"
        @click.prevent="runPrompt(prompt)"
      >
        <v-icon icon="mdi-magnify" />
        <div class="font-weight-bold text-body-1">
          {{ prompt.text }}
        </div>

        <v-spacer />
        <v-icon icon="mdi-chevron-right" />
      </v-card-actions>
    </v-card-text>
  </div>
</template>
<script>
export default {
  name: "IndicatorSearchSuggestions",
  data: () => ({
    suggestedPrompts: [],
  }),
  mounted() {
    this.getSearchSuggestions();
  },
  props: {
    filtersProp: {
      required: true,
    },
    getSuggestionsAPI: {
      required: true,
    },
  },
  computed: {
    filters: {
      get() {
        return this.filtersProp;
      },
      set(val) {
        this.$emit("update:filtersProp", val);
      },
    },
  },
  methods: {
    async getSearchSuggestions() {
      try {
        const response = await this.$axios.get(this.getSuggestionsAPI);

        this.suggestedPrompts = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    runPrompt(suggestion) {
      /**
       * translate filter suggestions into tags
       * search is triggered by filter.tags watcher
       */
      this.setSearchSuggestionFilters(suggestion);
    },
    /** extracts and sets search string and tags from filter suggestion data */
    setSearchSuggestionFilters(suggestion) {
      let allFilters = [];
      //compile suggestion filter tags
      suggestion.filters.forEach((f) => {
        const filterParts = f.split("|").map((item) => item.trim());

        let filter = {
          title: "",
          filterBy: null,
          operator: null,
          text: null,
          date: null,
          dateLabel: null,
          country: null,
        };
        if (filterParts[0] == "coverage") {
          const [type, country] = [...filterParts];
          filter.filterBy = type;
          filter.country = [country];
        }

        if (filterParts[0] === "source") {
          const [type, operator, text] = [...filterParts];
          filter.filterBy = type;
          filter.operator = operator;
          filter.text = text;
        }

        if (filterParts[0] === "timepoint") {
          const [type, operator, date] = [...filterParts];
          const [year, month] = [...date.split("-")];
          filter.filterBy = type;
          filter.operator = operator;
          filter.date = date;
          filter.dateLabel =
            month.charAt(0).toUpperCase() + month.slice(1) + " " + year;
        }

        if (filterParts[0] === "data_type") {
          const [type, operator] = [...filterParts];
          filter.filterBy = type;
          filter.operator = operator;
        }

        allFilters.push(filter);
      });

      //update current filter tags and search string
      this.filters.tags = allFilters;

      //search string
      if (suggestion.search) {
        this.filters.searchString = suggestion.search;
      }

      //if only a search string is set (no tags) - trigger a search
      if (suggestion.filters.length == 0 && this.filters.searchString) {
        this.$emit("search");
      }
    },
  },
};
</script>
